/* IMPORT */

import { calendarActions, requestActions } from 'services'

/* EXPORT */

export const mapStateToProps = (state) => ({
    request: state.request,
    restaurants: state.restaurants.list,
    events: state.calendar.events,
    summary: state.calendar.summary,
    month: state.calendar.month,
    monthLoading: state.calendar.month_loading,
    eventsLoading: state.calendar.events_loading,
    sub: state.calendar.sub,
    factsNumbers: state.calendar.factsNumbers
})

export const allActions = {
    ...calendarActions,
    ...requestActions,
}
