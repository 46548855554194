/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'rating' ),
      rhelper = format.helper.basic( 'restaurants' )

function typeKey ( endpoint, type ) {
    if ( type === 'categoryDish' ) {
        return {
            type: 'categoryDishes',
            key:  'productUid',
        }
    }

    switch ( endpoint ) {
        case 'top-categories':
            return {
                type: 'categories',
                key:  'categoryId',
            }
        case 'top-modifiers':
            return {
                type: 'modifiers',
                key:  'productUid',
            }
        case 'top-products':
        default:
            return {
                type: 'dishes',
                key:  'productUid',
            }
    }
}

/* TOP */

export const flushTopError = update( 'top.error', ( value, state ) => ({
    ...state,
    top: { error: null },
}))

export const toggleTop = asyncUpdate(
    'top.set',
    ( data ) => {
        return data.top ? api.setTop( data ) : api.unsetTop( data )
    },
    ( event, state ) => {
        const { status, data } = format.extract.update( event ),
              { type, key } = typeKey( data.endpoint, data._type ),
              sub = type === 'categoryDishes' ? 'data' : 'content',
              { arr, index } = format.extract.update(
                  event,
                  state.rating[ type ],
                  key,
                  sub
              )

        if ( status === 200 && !data._deep ) {
            arr[ sub ][ index ] = format.copy.object( data )
        }

        arr._updated = data

        if ( status !== 200 ) {
            arr._updated.top = !data.top
            !data._deep && ( arr[ sub ][ index ] = format.copy.object( arr[ sub ][ index ]))
        }

        return helper( type, structuredClone(arr), state )
    }
)

export const savePlan = asyncUpdate(
    'plan.set',
    ( data ) => {
        switch ( data._type ) {
            case 'category':
                return api.setCategoryPlan( data )
            case 'modifier':
                return api.setModPlan( data )
            case 'dish':
            default:
                return api.setDishPlan( data )
        }
    },
    ( event, state ) => state
)

/* RESTAURANTS */

export const flushOrigins = update( 'rest.admin.origins.flush', ( _, state ) => ({
    ...state,
    restaurants: {
        ...state.restaurants,
        origins: null,
    },
}))

export const loadRestaurants = asyncUpdate(
    'rest',
    ( numbers ) => api.getRestaurants({ numbers: numbers.join( ',' ) }),
    ( event, state ) => {
        let list =
      event.status === 200
          ? event.data
          : [ { id: '-1', name: 'Unknown Restaurant' } ]

        if ( !Array.isArray( list )) {
            list = [ list ]
        }

        const currentRest = event.data.find((e) => String(e.factsNumber) === state.request.restaurantId)

        return rhelper( 'list', list,
            {
                ...state,
                request: {
                    ...state.request,
                    zoneId: currentRest.timeZoneName
                },
                restaurants: {current: currentRest}
            }
        )
    }
)

export const setCurrentRestaurant = update('rest.current', ( restaurantId, state ) => {
    const current = state.restaurants.list.find(( r ) => r.factsNumber === parseInt( restaurantId ))

    return ({
        ...state,
        restaurants: {
            ...state.restaurants,
            current,
        },
    })
})

export const getRestaurantsData = asyncUpdate(
    'rest.admin.data',
    ( numbers ) => api.getRestaurants({ numbers: numbers.join( ',' ) }),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return { ...state }
        }

        let list = event.data

        if ( !Array.isArray( list )) {
            list = [ list ]
        }

        return rhelper( 'data', list, state )
    }
)

export const flushGrantedRestaurants = update(
    'rest.admin.granted.flush',
    ( data, state ) => rhelper( 'granted', null, state )
)

export const getGrantedRestaurants = asyncUpdate(
    'rest.admin.granted',
    ( numbers ) => api.getRestaurants({ numbers: numbers.join( ',' ) }),
    ( event, state ) => {
        let list = event.data

        if ( !Array.isArray( list )) {
            list = [ list ]
        }

        return rhelper( 'granted', list, state )
    }
)

export const getSameFranchisee = asyncUpdate(
    'rest.admin.sameFranchisee',
    ( rid ) => api.getSameFranchisee( rid ),
    ( event, state ) => {
        return {
            ...state,
            restaurants: {
                ...state.restaurants,
                franchisee: event.data,
            },
        }
    }
)

export const getSameFranchiseeOrigins = asyncUpdate(
    'rest.admin.sameFranchisee.origins',
    ( rid ) => api.getSameFranchisee( rid ),
    ( event, state ) => {
        return {
            ...state,
            restaurants: {
                ...state.restaurants,
                origins: event.data,
            },
        }
    }
)

export const fetchRestaurantsList = asyncUpdate(
    'rest.admin.restaurants',
    ( search ) => api.getRestaurantsList({ search, page: 0 }),
    ( event, state ) => {
        let data = event.data
        if ( event.status !== 200 ) {
            data.content = []
        }
        return rhelper( 'admin', data, state )
    }
)

export const fetchRestaurantsUsersList = asyncUpdate(
    'rest.admin.restaurants.by.areacoach',
    ( search ) =>
        api.getRestaurantsUsersList({
            jobRole: 'Area Coach',
            search,
            page:    0,
        }),
    ( event, state ) => {
        let data = event.data
        if ( event.status !== 200 ) {
            data = { content: [] }
        }
        return rhelper( 'adminAreaCoach', data, state )
    }
)

export const fetchRestaurantsFZList = asyncUpdate(
    'rest.admin.restaurants.by.fz',
    ( franchiseeName ) =>
        api.getRestaurantsFZList({
            franchiseeName,
            page: 0,
        }),
    ( event, state ) => rhelper( 'adminFZ', format.generate.noPager( event.data || []), state )
)

export const appendRestaurantsFZList = asyncUpdate(
    'rest.admin.append.by.fz',
    ( params ) => api.getRestaurantsFZList( params ),
    ( event, state ) => {
        let content = event.data

        const number = event.config.params.page,
              last = content.length === 0

        state.restaurants.adminFZ &&
      ( content = [ ...state.restaurants.adminFZ.content, ...content ])

        return {
            ...state,
            restaurants: {
                ...state.restaurants,
                adminFZ: {
                    ...state.restaurants.adminFZ,
                    content,
                    number,
                    last,
                },
            },
        }
    }
)

export const appendRestaurantsUsersList = asyncUpdate(
    'rest.admin.append.by.areacoach',
    ( params ) =>
        api.getRestaurantsUsersList({
            jobRole: 'Area Coach',
            ...params,
        }),
    ( event, state ) => {
        let content = event.data

        const number = event.config.params.page,
              last = content.content.length === 0

        state.restaurants.adminAreaCoach &&
      ( content = [
          ...state.restaurants.adminAreaCoach.content,
          ...content.content,
      ])

        return {
            ...state,
            restaurants: {
                ...state.restaurants,
                adminAreaCoach: {
                    ...state.restaurants.adminAreaCoach,
                    content,
                    number,
                    last,
                },
            },
        }
    }
)

export const appendRestaurantsList = asyncUpdate(
    'rest.admin.append',
    ( params ) => api.getRestaurantsList( params ),
    ( event, state ) => {
        let content = event.data

        const number = event.config.params.page,
              last = content.content.length === 0

        state.restaurants.admin &&
      ( content = [ ...state.restaurants.admin.content, ...content.content ])

        return {
            ...state,
            restaurants: {
                ...state.restaurants,
                admin: {
                    ...state.restaurants.admin,
                    content,
                    number,
                    last,
                },
            },
        }
    }
)

export const removeGuestChecks = asyncUpdate(
    'rest.removeGuestChecks.remove',
    ( params ) => api.removeGuestChecks( params ),
    ( event, state ) => ({
        ...state,
        restaurants: {
            ...state.restaurants,
            admin: {
                ...state.restaurants.admin,
                guestChecks: event,
            },
        },
    })
)

/* RESTAURANTS TYPES */

export const fetchRestaurantTypes = asyncUpdate(
    'rest.types',
    () => api.getRestaurantTypes(),
    ( event, state ) => ({
        ...state,
        restaurants: {
            ...state.restaurants,
            types:     event.data,
            typeError: event.status !== 200
        }
    })
)

export const fetchFacilityTypes = asyncUpdate(
    'rest.facilityTypes',
    () => api.getFacilityTypes(),
    ( event, state ) => ({
        ...state,
        restaurants: {
            ...state.restaurants,
            facilityTypes:      event.data,
            facilityTypesError: event.status !== 200
        }
    })
)
