import React, {useState} from 'react';
import {Button, Card, Tabs} from 'antd';
import ReportExcelTooltip from '../../layout/ReportExcelTooltip/ReportExcelTooltip';
import InnerContent from '../../layout/InnerContent/InnerContent';
import StatisticsTab from '../statistics/StatisticsTab/StatisticsTab';
import CustomTab from '../../layout/CustomTab/CustomTab';
import moment from 'moment';
// @ts-ignore
import ReactExport from 'react-data-export'
import {Download} from 'components/layout/icons';
import Icon from '@ant-design/icons';
import {FormatedDateFilters} from 'pages/GuestVote/types';
import {DataGroupType, PostMetricsSearchResponse} from 'services/guestVote/type';
import {PickerType} from '../GuestVoteControls/types';
import {format} from '../../../tools';
import {STORAGE_KEY} from '../GuestVoteTable/GuestVoteTable';
import {dropTypesFilter} from '../GuestVoteTable/const';
import {getStatisticsTableColumns} from '../statistics/StatisticsTab/const';
import config from 'config';
import {GustVoteTabsStoreProps} from './connector';
import {connect} from 'react-redux';
import {mapStateToProps} from './connector';

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

type GuestVoteTabsProps = {
    dateFilters: FormatedDateFilters,
    picker: PickerType
}

type GuestVoteControlsDateRange = {
    dateRange: [string, string];
}

export const GuestVoteTabs = ({dateFilters, picker, happinessIndexesSummary, happinessIndexesSearch, happinessIndexesSummaryIsLoading, happinessIndexesSearchIsLoading}: GuestVoteTabsProps & GustVoteTabsStoreProps) => {
    const [ activeTab, setActiveTab ] = useState('statistics');
    const currentTypesFilter = (sessionStorage.getItem(STORAGE_KEY) ? sessionStorage.getItem(STORAGE_KEY) : 'RESTAURANTS') as DataGroupType;

    const summaryTableData = () => {
        let newHappinessIndexSummary = format.copy.object(happinessIndexesSummary);
        let newHappinessIndexesSearch = format.copy.object(happinessIndexesSearch) as PostMetricsSearchResponse;

        newHappinessIndexSummary._alwaysOnTop = true;
        newHappinessIndexSummary.name = `Все ${dropTypesFilter[ currentTypesFilter ]}`;
        newHappinessIndexesSearch.content = [ newHappinessIndexSummary, ...newHappinessIndexesSearch.content ]

        return newHappinessIndexesSearch
    }

    const prepareExcel = (name: string) => {
        const newHappinessIndex = summaryTableData();
        const red = '00FF0000';
        const green = '00038E1D';
        const black = '00000000';

        const columns = getStatisticsTableColumns(currentTypesFilter as DataGroupType).map((item) => ({...item, width: {wpx: item.width ?? 200}}));
        if (currentTypesFilter === 'RESTAURANTS') {
            columns.unshift({
                title: 'Код',
                dataIndex: 'id',
                key: 'id',
                width: {wpx: 100},
                sorter: (a: { factHappinessIndex: number; }, b: { factHappinessIndex: number; }) => a.factHappinessIndex - b.factHappinessIndex
            });
        }

        const data = newHappinessIndex.content.map((item) => {
            const row = [
                {value: item.name === null ? config.ui.nullSymbol : item.name},
                {value: item.factHappinessIndex === null ? config.ui.nullSymbol : item.factHappinessIndex},
                {value: item.factHappinessIndexLastYear === null ? config.ui.nullSymbol : item.factHappinessIndexLastYear},
                {
                    value: item.deltaFactHappinessIndexLastYear === null ? config.ui.nullSymbol : item.deltaFactHappinessIndexLastYear,
                    //@ts-ignore
                    style: {font: {color: {rgb: item.deltaFactHappinessIndexLastYear < 0 ? red : item.deltaFactHappinessIndexLastYear > 0 ? green : black}}}
                },
                {value: item.factOsat === null ? config.ui.nullSymbol : `${item.factOsat}%`},
                {value: item.factOsatLastYear === null ? config.ui.nullSymbol : `${item.factOsatLastYear}%`},
                {
                    value: item.deltaFactOsatLastYear === null ? config.ui.nullSymbol : `${item.deltaFactOsatLastYear}%`,
                    // @ts-ignore
                    style: {font: {color: {rgb: item.deltaFactOsatLastYear < 0 ? red : item.deltaFactOsatLastYear > 0 ? green : black}}}
                },

                {value: item.factFeedbacksRating === null ? config.ui.nullSymbol : item.factFeedbacksRating},
                {value: item.factFeedbacksRatingLastYear === null ? config.ui.nullSymbol : item.factFeedbacksRatingLastYear},
                {value: item.factFeedbacks === null ? config.ui.nullSymbol : item.factFeedbacks},
                {value: item.factTransactions === null ? config.ui.nullSymbol : item.factTransactions},
                {value: item.factFeedbacksPer10KTransactions === null ? config.ui.nullSymbol : item.factFeedbacksPer10KTransactions},
            ];

            if (currentTypesFilter === 'RESTAURANTS') {
                row.unshift({value: item.id === null ? config.ui.nullSymbol : item.id});
            }

            return row;
        });

        const result = [
            {
                columns: columns,
                data: data,
            }
        ];

        return <ExcelSheet dataSet={result} name={name}/>;
    };

    const tabs = [
        // {
        //     key: 'HI',
        //     label: 'Рейтинг HI',
        //     children: 'Рейтинг HI'
        // },
        {
            key: 'statistics' as const,
            label: 'Статистика',
            children: <StatisticsTab dateFilters={dateFilters} picker={picker}/>
        },
        // {
        //     key: 'request rating',
        //     label: 'Рейтинг по обращениям',
        //     children: 'Рейтинг по обращениям'
        // },
        // {
        //     key: 'consolidated statistics',
        //     label: 'Консолидированная статистика',
        //     children: 'Консолидированная статистика'
        // }
    ]

    const tabExcelBlockerConditions = {
        'HI': true,
        'statistics': happinessIndexesSummaryIsLoading || happinessIndexesSearchIsLoading,
        'request rating': true,
        'consolidated statistics': true
    }

    const getCorrectedDateRange = (picker: PickerType, dateRange: [string, string]): [string, string] => {
        const [ startDate, endDate ] = dateRange;

        if (picker === 'year') {
            const startYear = moment(startDate).year();
            const correctedStartDate = moment().set('year', startYear).startOf('year').format('YYYY-MM-DD');
            return [ correctedStartDate, endDate ];
        } else if (picker === 'month') {
            const startMonth = moment(startDate).month();
            const endMonth = moment(endDate).month();
            const correctedStartDate = moment().set('month', startMonth).startOf('month').format('YYYY-MM-DD');
            const correctedEndDate = moment().set('month', endMonth).endOf('month').format('YYYY-MM-DD');
            return [ correctedStartDate, correctedEndDate ];
        }

        return [ startDate, endDate ];
    };

    const getCurrentExcelDate = (): string => {
        const storedData = sessionStorage.getItem('guestVoteControls');
        const currentDate: GuestVoteControlsDateRange | null = storedData ? JSON.parse(storedData) : null;

        if (currentDate && currentDate.dateRange) {
            const correctedDateRange = getCorrectedDateRange(picker, currentDate.dateRange);
            const [ startDate, endDate ] = correctedDateRange;

            if (startDate !== endDate) {
                return `с ${moment(startDate).format('DD.MM.YYYY')} по ${moment(endDate).format('DD.MM.YYYY')}`;
            }

            return moment(startDate).format('DD.MM.YYYY');
        }

        return moment().format('DD.MM.YYYY');
    };


    const filtredTabs = tabs.map((tab) => ({
        ...tab,
        label:
            <CustomTab
                title={tab.label}
                icon={
                    activeTab !== tab.key || tabExcelBlockerConditions[ tab.key ] ?
                            <ReportExcelTooltip/> :
                            <ExcelFile
                                filename={`${tab.key}_${getCurrentExcelDate()}`}
                                element={<Button
                                    type='link'
                                    className='button-download'
                                    disabled={activeTab !== tab.key}
                                >
                                    <Icon
                                        component={Download.def}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}/>
                                </Button>}
                            >
                                {prepareExcel(tab.key)}
                            </ExcelFile>}
                onClickIcon={undefined}
            />,
    }))

    return (
        <InnerContent>
            <Card bordered={false}>
                <Tabs animated={false} items={filtredTabs} onChange={(tab) => setActiveTab(tab)}/>
            </Card>
        </InnerContent>
    );
};

export default connect(mapStateToProps)(GuestVoteTabs)
