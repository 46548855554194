import axios, {AxiosResponse} from 'axios';
import config from '../../config';
import {
    PostFeedbackFiltersSearchPayload,
    PostFeedbackFiltersSearchResponse,
    PostMetricsPayload,
    PostMetricsSearchBody,
    PostMetricsSummaryPayload,
} from './type';
import {format} from '../../tools';

const service = 'feedback'

export const postFeedbackFiltersSearch = (body: PostFeedbackFiltersSearchPayload) => {
    return axios.post<PostFeedbackFiltersSearchPayload, AxiosResponse<PostFeedbackFiltersSearchResponse>>(
        config.api[ service ] + 'feedbacks/feedback-filters/search',
        body,
        {...config.api.options})
}

export const postMetrics = (body: PostMetricsPayload) =>
    format.generate.post(service, 'metrics', body, '', 'charts')

export const postMetricsByHour = (body: PostMetricsPayload) =>
    format.generate.post(service, 'metrics', body)

export const postMetricsSummary = (body: PostMetricsSummaryPayload) =>
    format.generate.post(service, 'metrics/summary', body)

export const postMetricsSearch = ({body, params}: {body: PostMetricsSearchBody, params?: { page?: number }}) =>
    format.generate.post(service, 'metrics/search', body, '', '', '', '', {params})
