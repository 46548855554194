/* VENDOR */
import React, { Component }          from 'react'
import PropTypes                     from 'prop-types'
import { Card, Alert, Modal, Radio } from 'antd'

/* APPLICATION */
import { ReportTable, AddButton, Spinner } from 'components'
import { format }                          from 'tools'
import config                              from 'config'

class Breaks extends Component {
    static propTypes = {
        breaks: PropTypes.array,
        update: PropTypes.func,
        remove: PropTypes.func,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            rates:   [],
            modal:   false,
            rate:    -1,
            working: false,
        }

        this.set = format.generate.set( this )
    }

    componentDidMount () {
        this.init( this.props.breaks )
    }

    componentDidUpdate ( prevProps ) {
        const { breaks, rates } = this.props;

        ( breaks !== prevProps.breaks || rates !== prevProps.rates ) &&
      this.init( breaks )
    }

    init = ( raw ) => {
        const dict = this.props.rates

        if ( !dict || !raw ) { return }

        const all = raw,
              rates = all.filter(( r ) => r.breaks.length > 0 )

        this.set.rates( rates )
        setTimeout(() => this.set.working( false ))
    }

    check = ( rec, key, val ) => key === 'paid' && this.change( rec, key, val )

    change = ( record, key, val ) => {
        const rec = format.copy.object( record )

        rec[ key ] = val
        rec.breakId.substr( 0, 4 ) === 'tmp-' && ( rec.breakId = null )

        this.props.update( rec )
    }

    removeUnsaved = ( record ) => {
        const rates = format.copy.array( this.state.rates ),
              index = format.find.index( rates, 'rate', record.rate ),
              rate = format.copy.object( rates[ index ]),
              breaks = format.copy.array( rate.breaks )

        breaks.splice( rates.indexOf( record ), 1 )
        rate.breaks = breaks
        rates[ index ] = rate

        this.set.working( true )
        setTimeout(() => this.init( rates ), 0 )
    }

    removeSaved = ( record ) => {
        this.set.working( true )
        this.props.remove( record )
    }

    remove = ( record ) =>
        record.__unsaved ? this.removeUnsaved( record ) : this.removeSaved( record )

    add = ( rate ) => {
        return () => {
            const add = format.copy.object( config.defs.breaks )

            add.rate = rate.rate

            this.set.working( true )
            this.props.update( add )
        }
    }

    append = () => {
        const { rate } = this.state

        this.add({ rate })()

        this.close()
    }

    create = () => this.set.modal( true )
    close = () =>
        this.set.state({
            modal: false,
            rate:  -1,
        })

    setRate = ( e ) => this.set.rate( e.target.value )

    availableRates = () => {
        const { rates } = this.props,
              added = this.state.rates,
              res = []

        if ( !rates ) { return null }

        rates
            .sort(( a, b ) => a.rate - b.rate )
            .forEach(( rate ) => {
                if ( !added.find(( r ) => r.rate === rate.rate )) {
                    res.push(
                        <Radio.Button key={rate.rate} value={rate.rate}>
                            {rate.rate}
                        </Radio.Button>
                    )
                }
            })

        return res
    }

    table = ( rate ) => (
        <div className="report-table offset" key={rate.rate}>
            <h2>
                {this.props.rates?.find(( r ) => r.rate === rate.rate )?.rateFullname}
            </h2>

            {rate.breaks.length > 0 ? (
                <ReportTable
                    data={format.generate.noPager( rate.breaks )}
                    columns={config.tables.breaks}
                    rowKey="breakId"
                    loading={false}
                    onCellBlur={this.change}
                    onChange={this.check}
                    onRemove={this.remove}
                    onAdd={this.add( rate )}
                />
            ) : (
                <div className="tabbed-footer nopad">
                    <AddButton text="Добавить" action={this.add( rate )} />
                </div>
            )}
        </div>
    )

    content = ( rates ) => rates.sort(( a, b ) => a.rate - b.rate ).map( this.table )

    render () {
        const { modal, rates, rate, working } = this.state,
              availableRates = this.availableRates()

        return (
            <div className="breaks-card">
                <Card bordered={false}>
                    {( rates?.length < 1 ||
            rates?.map(( r ) => r.breaks?.length < 1 ).includes( true )) && (
                        <Alert
                            message="Чтобы сформировать расписание, добавьте хотя бы один перерыв каждой ставке"
                            type="warning"
                        />
                    )}
                    {this.content( rates )}
                    {availableRates?.length > 0 && (
                        <div className="tabbed-footer nopad">
                            <AddButton text="Добавить" action={this.create} />
                        </div>
                    )}

                    {working && (
                        <div className="saving-overlay">
                            <Spinner />
                        </div>
                    )}
                </Card>
                <Modal
                    centered
                    open={modal}
                    title="Добавление ставки"
                    className="add-rate-modal"
                    width={400}
                    okText="Добавить"
                    okButtonProps={{
                        className: 'wide-btn',
                        disabled:  rate === -1 || !!rates.find(( r ) => r.rate === rate ),
                    }}
                    onOk={this.append}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onCancel={this.close}
                >
                    <Radio.Group onChange={this.setRate} value={rate} buttonStyle="solid">
                        {availableRates}
                    </Radio.Group>
                </Modal>
            </div>
        )
    }
}

export default Breaks
