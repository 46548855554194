import moment from 'moment'

import { format } from 'tools'
import config     from 'config'

export const getTab = ( date ) => {
    let res = date

    if ( res === moment().startOf( 'week' ).format( config.format.dayAPI )) {
        return 'current'
    }

    if (
        res === moment().add( 1, 'week' ).startOf( 'week' ).format( config.format.dayAPI )
    ) {
        return 'next'
    }

    return res
}

export const schedule = ( schedule, setDay, oldDay ) => {
    const dow =
      setDay &&
      moment().startOf( 'week' ).format( config.format.dayAPI ) ===
        schedule.dateStart.split( 'T' )[ 0 ]
          ? moment().format( 'd' )
          : oldDay,
          day = firstDay( schedule.days, dow ).toString(),
          date = offsetDate( schedule.dateStart, parseInt( day )),
          { from, to } = workHours( day )

    return { day, dow, date, from, to }
}

export const find = ( days, day ) =>
    days.find(( d ) => d.dayOfWeek === parseInt( day ))

export const firstDay = ( days, dow ) => {
    const found = find( days, dow )

    if ( found ) {
        return found.dayOfWeek
    }

    return parseInt( dow ) === 0 ? 7 : dow
}

export const offsetDate = ( start, offset ) =>
    start
        ? moment( start, config.format.date + ':ss' ).add( offset - 1, 'days' )
        : moment().startOf( 'week' )

export const workHours = () => ({
    from: moment().startOf( 'day' ),
    to:   moment().endOf( 'day' ),
})

export const findLack = ( hours, from, inverse ) => {
    for ( let i = from + 1; i < hours.length; i++ ) {
        if ( inverse ) {
            if ( hours[ i ].delta >= 0 && i < hours.length - 1 ) {
                if ( hours[ i + 1 ].delta >= 0 ) {
                    return hours[ i ].hour
                }
            } else if ( hours[ i ].delta >= 0 ) {
                return hours[ i ].hour
            }
        } else {
            if ( hours[ i ].delta < 0 ) {
                return hours[ i ].hour
            }
        }
    }

    return 24
}

export const findLength = ( length, durs ) => {
    const min = format.extract.min( durs ),
          max = format.extract.max( durs ),
          sorted = durs.sort(( a, b ) => a - b )

    //Just for calculartions
    if ( length < min ) { return length }

    //If precise length exist
    if ( sorted.indexOf( length ) > -1 ) { return length }

    //Finding the best split
    for ( let i = 0; i < sorted.length; i++ ) {
        const pair = length - sorted[ i ]

        if ( durs.indexOf( pair ) > -1 ) {
            return pair
        }
    }

    if ( length > max ) {
        return max
    }

    //Search for nearest shift
    for ( let i = 0; i < sorted.length - 1; i++ ) {
        if ( length >= sorted[ i ] && length < sorted[ i + 1 ]) {
            return sorted[ i ]
        }
    }

    return length
}

export const maxHours = ( data, rawDow, wh, sideDays ) => {
    if ( !data || !sideDays ) { return }

    const dow = parseInt( rawDow ),
          pdow = dow - 1,
          ndow = dow + 1,
          day = find( data.days, dow ),
          nday = ndow <= 7 ? find( data.days, ndow ) : hourize( sideDays.next ),
          pday = pdow >= 1 ? find( data.days, pdow ) : hourize( sideDays.prev ),
          whd = find( wh.days, dow ),
          whp = wh.days.find(( d ) =>
              pdow === 0 ? d.dayOfWeek === 7 : d.dayOfWeek === pdow
          ),
          maxHours = []

    if ( parseInt( whd.timeStart ) > parseInt( whd.timeEnd )) {
        const last = whp.shifts[ whp.shifts.length - 1 ]

        maxHour( last, pday, day, maxHours, -24 )
    }

    whd.shifts.forEach(( shift ) => maxHour( shift, day, nday, maxHours ))

    return maxHours.filter(
        ( h, i ) => maxHours.indexOf( h ) === i && h > -1 && h < 24
    )
}

export const hourize = ( data ) => ({
    hours: data.data.map(( d ) => ({
        hour:     parseInt( d.label ),
        turnover: d.planValue,
    })),
})

export const maxHour = ( shift, day, nday, maxHours, rawOffset ) => {
    if ( !nday || !day ) { return }

    const offset = rawOffset || 0,
          start = parseInt( shift ? shift.timeStart : 0 ),
          rawEnd = parseInt( shift ? shift.timeEnd : 0 ),
          end = rawEnd < start ? rawEnd + 24 : rawEnd

    if ( start === end ) {
        return
    }
    
    let maxHour = -1,
        max = -Infinity

    for ( let i = start; i < end; i++ ) {
        let hdata =
        i > 23
            ? nday.hours
                ? nday.hours.find(( h ) => h.hour === i - 24 )
                : null
            : day.hours.find(( h ) => h.hour === i ),
            to = hdata ? hdata.planTurnover : -Infinity

        max !== Math.max( max, to ) && ( maxHour = i )
        max = Math.max( max, to )
    }

    maxHours.push( maxHour + offset )
}
