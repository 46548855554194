/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Link }             from 'react-router-dom'
import { Layout }           from 'antd'

/* APPLICATION */
import {
    DashboardCard,
    PerformanceChart,
    PerformanceSummary,
} from 'components'
import config from 'config'

import './performance-card.scss'

const { Sider, Header, Content } = Layout

class PerformanceCard extends Component {
    static propTypes = {
        performance: PropTypes.object,
        showDetails: PropTypes.bool,
        offset:      PropTypes.string,
    }

    render () {
        const { performance, showDetails, offset } = this.props,
              { summary, planAndFact } = performance

        return (
            <DashboardCard spans={[ 24, 10 ]} className="performance-card">
                <Layout>
                    <Header>
                        <div className="card-controls">
                            <h2>Производительность</h2>
                            <div className="control-links">
                                <Link to="/dashboard/performance">Отчёт</Link>
                            </div>
                        </div>
                    </Header>
                    <Content>
                        <Layout>
                            <Sider className="auto-sider">
                                <PerformanceSummary
                                    data={summary?.content[ 0 ] ? summary?.content[ 0 ] : null }
                                    showDetails={showDetails}
                                    goal={
                                        summary?.content[ 0 ]
                                            ? summary?.content[ 0 ]?.goalSpmh
                                                ? summary?.content[ 0 ]?.goalSpmh
                                                : config.ui.nullSymbol
                                            : null
                                    }
                                />
                            </Sider>
                            <Content>
                                <PerformanceChart
                                    height={showDetails ? 200 : 170}
                                    plan={planAndFact?.plan || []}
                                    fact={planAndFact?.fact || []}
                                    offset={offset}
                                />
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
            </DashboardCard>
        )
    }
}

export default PerformanceCard
