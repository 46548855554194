/* eslint-disable camelcase */
/* VENDOR */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Scrollbars} from 'react-custom-scrollbars'

import {Form} from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import {Row, Col, Modal} from 'antd'

/* APPLICATION */
import {FormField, Icons, Spinner} from 'components'
import {format} from 'tools'
import config from 'config'
import {connect} from 'react-redux';
import {mapStateToProps, allActions} from './connector.js'

import './template-card.scss'
import Icon from '@ant-design/icons';

const strategies = {
    none: 'Нет',
    yes: 'При нажатии ДА',
    no: 'При нажатии НЕТ',
    common: 'Общее'
},
      strategiesMask = [
          {
              value: 16,
              label: 'Зафиксировать отклонение',
          },
          {
              value: 2,
              label: 'Устранено на месте',
          },
          {
              value: 1,
              label: 'Создать задачу',
          },
          {
              value: 4,
              label: 'Задача уже создана',
          },
          {
              value: 8,
              label: 'Не оценивается',
          },
      ],
      employeeTypes = {
          all: 'Все сотрудники',
          managers: 'Менеджеры',
          assignee: 'Кроме TM и Outstaff',
      },
      nameDelim = '_'

class TemplateCard extends Component {
    static propTypes = {
        loading: PropTypes.bool,

        groups: PropTypes.array,
        template: PropTypes.object,

        onSave: PropTypes.func,
        onChange: PropTypes.func,
        onReset: PropTypes.func,
    }

    constructor (props) {
        super(props)
        this.state = {
            confirm: false,
            template: {},
            components: [],
        }
    }

    componentDidMount () {
        this.props.template && this.extractComponents(this.props.template)
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps (nextProps) {
        nextProps.template !== this.props.template &&
    this.extractComponents(nextProps.template)
    }

    checkStrategy = (cid, num) => {
        const val =
      typeof cid === 'object'
          ? cid.confirmTaskStrategy
          : this.props.form.getFieldValue(
              cid + nameDelim + 'confirmTaskStrategy'
          )

        return Array.isArray(val) ? val.indexOf(num) > -1 : !!(val & num)
    }

    prepareValue = (type, val, cmp) => {
        switch (type) {
            case 'type':
                return val
            case 'required':
                return !val
            case 'valueData':
                return val
                    .sort((a, b) => a.order - b.order)
                    .map((v) => v.value)
                    .map((v, i) =>
                        cmp.currentValue === i ||
            (cmp.currentValues && cmp.currentValues.indexOf(i) > -1)
                            ? '*' + v
                            : v
                    )
                    .join('\n')
            case 'confirmTaskStrategy':
                return [ 16, 1, 2, 4, 8 ].map((v) => v & val).filter((v) => v)
            default:
                return val
        }
    }

    addComponent = () => {
        const template = format.copy.object(this.state.template),
              components = format.copy.array(this.state.components),
              nc = format.copy.object(config.defs.component)

        nc.id = format.generate.guid()

        nc.weight = components.length
        components.push(nc)
        this.applyData(template, nc)

        this.setState({
            template,
            components,
        })

        this.props.onChange()
    }

    remove = (cmp) => {
        return (e) => {
            e.preventDefault()
            const template = format.copy.object(this.state.template),
                  components = format.copy.array(this.state.components),
                  index = components.indexOf(components.find((c) => c.id === cmp.id))

            components.splice(index, 1)
            this.removeData(template, cmp)

            this.setState({
                template,
                components,
            })

            this.props.onChange()
        }
    }

    removeData = (template, component) => {
        Object.getOwnPropertyNames(component).forEach(
            (prop) => delete template[ component.id + nameDelim + prop ]
        )
    }

    applyData = (template, component) => {
        const sub = [ 'data', 'constrains' ]

        Object.getOwnPropertyNames(component).forEach((prop) => {
            sub.includes(prop)
                ? this.applySub(prop, component, template)
                : this.applyOne(template, component, prop)
        })
    }

    applySub = (sub, component, template) =>
        Object.getOwnPropertyNames(component[ sub ]).forEach(
            (prop) =>
                (template[ component.id + nameDelim + prop ] = this.prepareValue(
                    prop,
                    component[ sub ][ prop ],
                    component
                ))
        )

    applyOne = (template, component, prop) =>
        (template[ component.id + nameDelim + prop ] = this.prepareValue(
            prop,
            component[ prop ]
        ))

    extractComponents = (original) => {
        this.scrolls && this.scrolls.scrollToTop()

        if (!original || !original.templateBody) {
            this.setState({
                template: original,
                components: [],
            })

            return
        }

        const template = format.copy.object(original),
              body = JSON.parse(template.templateBody)

        body.sc && !body.components && (body.components = body.sc)

        if (!body.components) {
            return
        }

        body.components.map((c) => this.applyData(template, c))

        this.props.form.resetFields()

        this.setState(
            {
                template,
                components: body.components,
            },
            () => this.props.form.validateFields()
        )
    }

    moveBottomHandler = (cmp) => {
        const {components} = this.state
        const nc = format.copy.array(components)
        nc[ cmp.weight ].weight = cmp.weight + 1
        nc[ cmp.weight + 1 ].weight = cmp.weight

        this.setState({components: nc})
        this.props.onChange()
    }

    moveTopHandler = (cmp) => {
        const {components} = this.state
        const nc = format.copy.array(components)
        nc[ cmp.weight ].weight = cmp.weight - 1
        nc[ cmp.weight - 1 ].weight = cmp.weight

        this.setState({components: nc})
        this.props.onChange()
    }

    componentToField = (cmp) => {
        const { elementTypes } = this.props;
        const hasAttributes =
          elementTypes
              ?.find(item => {
                  return item.code === cmp.type;
              })
              ?.hasAttributes

        const componentsTypes = elementTypes?.reduce((acc, item) => {
            acc[ item.code ] = item.name
            return acc
        }, {})

        const positionButtons = []
        const topButton = {
            field: cmp.id + nameDelim + 'weight top',
            text: <Icon component={Icons.ArrowTop.def}/>,
            type: 'arrow',
            className: 'move-button',
            handler: () => this.moveTopHandler(cmp),
        }
        const bottomButton = {
            field: cmp.id + nameDelim + 'weight bottom',
            text: <Icon component={Icons.ArrowBottom.def}/>,
            type: 'arrow',
            className: 'move-button',
            handler: () => this.moveBottomHandler(cmp)
        }

        const purposesOptions = { none: 'Не указано' }
        if (this.props.elementAttributes && this.props.elementAttributes[ cmp.type ]) {
            this.props.elementAttributes[ cmp.type ].forEach((item) => {
                purposesOptions[ item.code ] = item.name
            })
        }

        if (cmp.weight === 0) {
            positionButtons.push(bottomButton)
        } else if (cmp.weight === this.state.components.length - 1) {
            positionButtons.push(topButton)
        } else {
            positionButtons.push(topButton)
            positionButtons.push(bottomButton)
        }

        const leftItems = [
            {
                field: cmp.id + nameDelim + 'type',
                label: 'Тип элемента',
                type: 'select',
                options: componentsTypes || [],
                isLoading: this.props.elementTypesLoading,
                byKey: true,
            },
        ]

        cmp._original_id = cmp.id
        cmp.type !== 'confirmStatistics' &&
    leftItems.push({
        field: cmp.id + nameDelim + 'required',
        label: 'Необязательный',
        type: 'checkbox',
    })
        hasAttributes &&
    leftItems.push({
        field: cmp.id + nameDelim + 'purpose',
        label: 'Атрибут',
        type: 'select',
        isLoading: this.props.elementAttributesLoading,
        options: purposesOptions,
        value: cmp.id === 'comment' ? 'comment' : 'none',
    })

        return {
            type: 'row',
            field: cmp.id,
            className: 'template-component',
            items: [
                {
                    type: 'column',
                    field: cmp.id + nameDelim + 'Sorter',
                    span: 2,
                    items: [ {
                        type: 'row',
                        field: cmp.id + nameDelim + 'arrows',
                        className: 'arrows',
                        items: positionButtons,
                    }, ],
                },
                {
                    type: 'column',
                    field: cmp.id + nameDelim + 'Left',
                    span: 7,
                    items: leftItems,
                },
                {
                    type: 'column',
                    field: cmp.id + nameDelim + 'Right',
                    span: 11,
                    items: this.componentItems(cmp),
                },
                {
                    type: 'column',
                    field: cmp.id + nameDelim + 'Add',
                    span: 4,
                    items: [
                        {
                            field: cmp.id + nameDelim + 'Remove',
                            label: 'Удалить',
                            type: 'remove',
                            handler: this.remove(cmp),
                        },
                    ],
                },
            ],
        }
    }

    componentItems = (cmp) => {
        const res = [],
              type = cmp.type,
              noDesc = [ 'proof', 'subtask' ],
              bigDesc = [ 'confirm' ],
              bigDescNoReq = [ 'confirmStatistics' ],
              labels = {
                  input: 'Подсказка',
                  dropdown: 'Заголовок',
                  confirmStatistics: 'Описание',
              }

        if (
            noDesc.indexOf(type) < 0 &&
      bigDesc.indexOf(type) < 0 &&
      bigDescNoReq.indexOf(type) < 0
        ) {
            res.push({
                field: cmp.id + nameDelim + 'desc',
                label: labels[ type ] || 'Формулировка',
                type: 'string',
                rules: [ config.rules.required ],
            })
        }

        if (bigDesc.indexOf(type) > -1) {
            res.push({
                field: cmp.id + nameDelim + 'desc',
                label: labels[ type ] || 'Формулировка',
                type: 'autoheight',
                rules: [ config.rules.required ],
            })
        }

        if (bigDescNoReq.indexOf(type) > -1) {
            res.push({
                field: cmp.id + nameDelim + 'desc',
                label: labels[ type ] || 'Формулировка',
                type: 'autoheight',
            })
        }

        switch (type) {
            case 'dropdown':
                res.push({
                    field: cmp.id + nameDelim + 'valueData',
                    label: 'Пункты меню (с новой строки)',
                    type: 'autoheight',
                    rules: [ config.rules.required ],
                })
                break
            case 'radio':
                res.push({
                    field: cmp.id + nameDelim + 'valueData',
                    label: 'Опции (с новой строки)',
                    type: 'autoheight',
                    rules: [ config.rules.required ],
                })
                res.push({
                    field: cmp.id + nameDelim + 'Help',
                    label: 'Используйте знак * в начале строки для значения по умолчанию',
                    type: 'label',
                })
                break
            case 'checkbox':
                res.push({
                    field: cmp.id + nameDelim + 'valueData',
                    label: 'Опции (с новой строки)',
                    type: 'autoheight',
                    rules: [ config.rules.required ],
                })
                res.push({
                    field: cmp.id + nameDelim + 'Help',
                    label: 'Используйте знак * в начале строки для значений по умолчанию',
                    type: 'label',
                })
                break
            case 'subtask':
                res.push({
                    field: cmp.id + nameDelim + 'todoButtonLabel',
                    label: 'Изначальная формулировка',
                    type: 'string',
                    rules: [ config.rules.required ],
                })

                res.push({
                    field: cmp.id + nameDelim + 'doneButtonLabel',
                    label: 'Финальная формулировка',
                    type: 'string',
                    rules: [ config.rules.required ],
                })
                break
            case 'input':
                res.push({
                    field: cmp.id + nameDelim + 'validator',
                    label: 'Валидатор (регулярное выражение)',
                    type: 'string',
                })
                break
            case 'datepicker':
                res.push({
                    field: cmp.id + nameDelim + 'maxDays',
                    label: 'Максимальное значение',
                    type: 'number',
                    value: cmp.constrains ? cmp.constrains.maxDays : 30,
                })
                break
            case 'proof':
                res.push({
                    field: cmp.id + nameDelim + 'maxItems',
                    label: 'Максимальное количество фотографий',
                    type: 'number',
                    value: 6,
                })
                break
            case 'confirm':
                res.push({
                    field: cmp.id + nameDelim + 'answerStrategy',
                    label: 'Действие',
                    type: 'select',
                    options: strategies,
                    value: 'none',
                    span: 12,
                })
                res.push({
                    field: cmp.id + nameDelim + 'confirmTaskRow',
                    type: 'row',
                    label: 'Опции',
                    span: 12,
                    hide: () => {
                        return (!this.props.form.getFieldValue(cmp.id + nameDelim + 'answerStrategy')
                          || this.props.form.getFieldValue(cmp.id + nameDelim + 'answerStrategy') === 'none'
                          || this.props.form.getFieldValue(cmp.id + nameDelim + 'answerStrategy') === 'Нет'
                        )
                    },
                    items: [
                        {
                            field: cmp.id + nameDelim + 'confirmTaskStrategy',
                            type: 'checkgroup',
                            options: strategiesMask,
                            span: 14,
                        },
                        {
                            field: cmp.id + nameDelim + 'allowComment',
                            label: 'Запрашивать комментарии',
                            type: 'checkbox',
                            value: cmp.allowComment === void 0 ? true : cmp.allowComment,
                            transform: (val) => {
                                if (val === void 0) {
                                    return true
                                }
                                return this.checkStrategy(cmp.id, 2) ||
                this.checkStrategy(cmp.id, 16)
                                    ? val
                                    : false
                            },
                            span: 10,
                            disabled: () =>
                                !(
                                    this.checkStrategy(cmp.id, 2) ||
                  this.checkStrategy(cmp.id, 16)
                                ),
                        },
                    ],
                })
                break
            case 'employeeDropdown':
                res.push({
                    field: cmp.id + nameDelim + 'employeeDropdown',
                    label: 'Тип сотрудников',
                    type: 'select',
                    options: employeeTypes,
                    value: 'all',
                })
                break
            default:
                break
        }

        return res
    }

    components = () =>
        this.state.components
            .sort((a, b) => a.weight - b.weight)
            .map(this.componentToField)

    fields = () => [
        {
            field: 'templateId',
            type: 'hidden',
        },
        {
            field: '__new',
            type: 'hidden',
        },
        {
            type: 'row',
            field: 'main',
            items: [
                {
                    field: 'templateName',
                    label: 'Название шаблона',
                    type: 'string',
                    autocomplete: false,
                    span: 10,
                    rules: [
                        {transform: (val) => (val ? val.trim() : val)},
                        config.rules.required,
                    ],
                },
                {
                    field: 'groupName',
                    label: 'Категория',
                    type: 'select',
                    options: format.generate.optionsr(
                        this.props.groups,
                        'groupName',
                        'categoryName'
                    ),
                    value: this.state.template.groupName,
                    byKey: true,
                    span: 8,
                    rules: [ config.rules.required ],
                },
                {
                    field: 'duration',
                    label: 'Длительность',
                    type: 'number',
                    span: 5,
                },
            ],
        },
        {
            type: 'row',
            field: 'submain',
            items: [
                {
                    field: 'needShowAttributes',
                    label: 'Отображать атрибуты задачи',
                    type: 'checkbox',
                    span: 10,
                },
                {
                    field: 'public',
                    label: 'Публичный',
                    type: 'checkbox',
                    span: 8,
                },
                {
                    field: 'active',
                    label: 'Активный',
                    type: 'checkbox',
                    span: 5,
                },
            ],
        },

        {
            type: 'hr',
            key: 'splitter',
        },

        ...this.components(),

        {
            type: 'row',
            field: 'actions',
            items: [
                {
                    type: 'add',
                    field: '_add_new',
                    text: 'Добавить элемент',
                    handler: this.addComponent,
                    span: 7,
                },
            ],
        },

        {
            type: 'row',
            field: 'buttons',
            items: [
                {
                    type: 'button',
                    style: 'remove',
                    field: 'remove',
                    text: this.state.template.__new
                        ? 'Удалить черновик'
                        : 'Удалить шаблон',
                    handler: this.showPopup,
                    span: 6,
                },
                {
                    type: 'space',
                    field: 'space',
                    span: 6,
                },
                {
                    type: 'button',
                    style: 'ghost',
                    field: 'unsave',
                    span: this.state.template.__new ? 0 : 6,
                    text: 'Не сохранять',
                    handler: this.reset,
                    hide: () => this.state.template.__new,
                },
                {
                    type: 'submit',
                    field: 'submit',
                    text: this.state.template.__new ? 'Добавить шаблон' : 'Сохранить',
                    span: 6,
                    disabled: (data) => !data.groupName || !data.templateName,
                },
            ],
        },
    ]

    column = (cfg) => {
        const res = cfg.items ? [] : this.field(cfg)

        if (cfg.items) {
            for (let i = 0; i < cfg.items.length; i++) {
                const hidden = cfg.items[ i ].hide
                    ? cfg.items[ i ].hide(this.state.template)
                    : false

                if (!hidden) {
                    res.push(this.field(cfg.items[ i ]))
                }
            }
        }

        return (
            <Col
                span={cfg.span}
                key={cfg.field}
                className={cfg.items ? 'field-column' : ''}
            >
                {res}
            </Col>
        )
    }

    row = (cfg) => {
        const res = []

        for (let i = 0; i < cfg.items.length; i++) {
            const hidden = cfg.items[ i ].hide
                ? !cfg.items[ i ].hide(this.state.template)
                : false

            if (!hidden) {
                res.push(this.column(cfg.items[ i ]))
            }
        }

        return (
            <React.Fragment key={cfg.field}>
                {cfg.label ? (
                    <div className="ant-form-item-label" key="label">
                        <label>{cfg.label}</label>
                    </div>
                ) : null}
                <Row
                    gutter={24}
                    key={cfg.field}
                    className={
                        'field-row ' +
            cfg.field +
            (cfg.className ? ' ' + cfg.className : '')
                    }
                >
                    {res}
                </Row>
            </React.Fragment>
        )
    }

    field = (cfg) => {
        let val = this.state.template ? this.state.template[ cfg.field ] : null

        !val && cfg.value && (val = cfg.value)

        if (cfg.type === 'row') {
            return this.row(cfg)
        }

        if (cfg.type === 'column') {
            return this.column(cfg)
        }

        if (cfg.type === 'hr') {
            return <hr key={cfg.key}/>
        }

        return (
            <FormField
                {...cfg}
                noPreserve={true}
                value={val}
                key={cfg.field}
                form={this.props.form}
                data={this.state.template}
                onChange={this.change}
            />
        )
    }

    removeMark = (val) => (val ? val.replace(/^\*/, '') : val)

    changeComponent = (split, val) => {
        const id = split[ 0 ],
              field = split[ 1 ],
              nc = format.copy.array(this.state.components),
              cmp = nc.find((c) => c.id === id)

        switch (field) {
            case 'required':
                cmp[ field ] = !val
                break
            case 'validator':
            case 'maxDays':
            case 'maxItems':
                !cmp.constrains && (cmp.constrains = {})
                cmp.constrains[ field ] = val
                break
            case 'valueData':
                // eslint-disable-next-line no-case-declarations
                const values = val.split(/\n/).filter((i) => i)

                !cmp.data && (cmp.data = {})

                cmp.data.valueData = values.map((value, order) => ({
                    order,
                    value: this.removeMark(value),
                }))

                switch (cmp.type) {
                    case 'radio':
                        cmp.currentValue = values.indexOf(
                            values.filter((i) => i[ 0 ] === '*')[ 0 ]
                        )
                        cmp.currentValue === -1 && (cmp.currentValue = null)
                        break
                    case 'checkbox':
                        cmp.currentValues = values
                            .filter((i) => i[ 0 ] === '*')
                            .map((v) => values.indexOf(v))
                        break
                    default:
                        break
                }

                break
            default:
                cmp[ field ] = val
        }

        this.setState({components: nc})
    }

    change = (key, val) => {
        const split = key.split(nameDelim)

        if (split.length > 1) {
            this.changeComponent(split, val)
        } else {
            const template = format.copy.object(this.state.template)

            template[ key ] = val

            this.setState({template})
        }

        this.props.onChange()
    }

    reset = (e) => {
        e.preventDefault()
        this.extractComponents(this.props.template)
        this.props.onReset()
        this.props.form.resetFields()
    }

    saveTemplate = (e) => {
        e.preventDefault()

        this.props.form.validateFieldsAndScroll((errors) => {
            if (errors) {
                return
            }

            const stemplate = this.state.template,
                  {components} = this.state,
                  {template, onSave} = this.props,
                  res = format.copy.object(template),
                  sc = format.copy.array(components)

            stemplate.duration && (res.duration = 0)

            sc.forEach((c, i) => {
                c.weight = i

                switch (c.type) {
                    case 'confirmStatistics':
                        c.id = 'rating'
                        c.required = false
                        break
                    case 'employeeDropdown':
                        c.id = 'employeeUuid'
                        break
                    case 'input':
                        // eslint-disable-next-line no-case-declarations
                        const oid = c._original_id === 'comment'
                            ? format.generate.guid()
                            : c._original_id

                        c.id = c.purpose === 'comment' ? 'comment' : oid
                        break
                    case 'confirm':
                        c.confirmTaskStrategy =
              c.answerStrategy !== 'none'
                  ? Array.isArray(c.confirmTaskStrategy)
                      ? c.confirmTaskStrategy.reduce((r, v) => r | v, 0)
                      : c.confirmTaskStrategy
                  : null
                        c.allowComment === void 0 && (c.allowComment = true)

                        c.allowComment = !(
                            this.checkStrategy(c, 2) || this.checkStrategy(c, 16)
                        )
                            ? false
                            : c.allowComment
                        break
                    default:
                        break
                }
            })

            Object.keys(res).map((prop) => (res[ prop ] = stemplate[ prop ]))
            res.templateBody = JSON.stringify({components: sc})
            onSave && onSave(res)
        })
    }

    removeTemplate = () => {
        this.props.onRemove()
        this.hidePopup()
    }

    showPopup = () =>
        this.setState({confirm: true})

    hidePopup = () =>
        this.setState({confirm: false})

    render () {
        const {loading} = this.props,
              {template} = this.state
        if (loading || !this.props.template) {
            return <Spinner/>
        }

        return (
            <div className="template-card view-type-switch fixed">
                <h2 className="template-name">
                    {template ? template.templateName : '...'}
                    {template && (
                        <span className="template-version">Версия {template.version}</span>
                    )}
                </h2>
                <Scrollbars
                    {...config.ui.scrolls}
                    ref={(node) => (this.scrolls = node)}
                >
                    <Form
                        {...config.ui.wideForm}
                        onSubmit={this.saveTemplate}
                        className="template-view"
                        name="TemplateCard"
                    >
                        {this.fields().map(this.field)}
                    </Form>

                    <Modal
                        title="Вы действительно хотите удалить шаблон? Это действие нельзя отменить"
                        className="kfc-popup"
                        centered={true}
                        open={this.state.confirm}
                        onOk={this.removeTemplate}
                        okText={'Да, удалить'}
                        okButtonProps={{className: 'wide-btn'}}
                        onCancel={this.hidePopup}
                        cancelButtonProps={{style: {display: 'none'}}}
                    />
                </Scrollbars>
            </div>
        )
    }
}

const TemplateCardWithForm = Form.create({name: 'TemplateCard'})(TemplateCard)
export default connect(mapStateToProps, allActions)(TemplateCardWithForm)
