import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {GuestVoteFilters, Spinner} from 'components';
import {GuestVoteColumn} from '../../charts';
import HappinessGauges from '../../HappinessGauges/HappinessGauges';
import GuestVoteTable, {STORAGE_KEY} from '../../GuestVoteTable/GuestVoteTable';
import styles from './statistics-tab.module.scss';
import {connect} from 'react-redux';
import {FormatedDateFilters} from 'pages/GuestVote/types';
import {DataGroupType, PostMetricsSearchResponse} from 'services/guestVote/type';
import {format} from 'tools';
import { dropTypesFilter} from '../../GuestVoteTable/const';
import {StatisticsTabStoreProps, allActions, mapStateToProps} from './connector';
import moment from 'moment';
import {PickerType} from '../../GuestVoteControls/types';
import config from 'config';
import GuestVoteMetricsTypeSwitcher from '../../GuestVoteMetricsTypeSwitcher/GuestVoteMetricsTypeSwitcher';

type StatisticsTabProps = {
    className?: string;
    dateFilters: FormatedDateFilters
    picker: PickerType
}

const StatisticsTab = (props: StatisticsTabProps & StatisticsTabStoreProps) => {
    const {
        className,
        currentFilters,
        postMetricsSummary,
        happinessIndexesSummary,
        dateFilters,
        picker,
        postMetricsSearch,
        appendHappinessIndexesSearch,
        happinessIndexesSearch,
        postMetricsByHour,
        postMetrics,
        happinessIndexes,
        happinessIndexesIsLoading,
        metricsType
    } = props;
    const currentDataGroup = (sessionStorage.getItem(STORAGE_KEY) ? sessionStorage.getItem(STORAGE_KEY) : 'RESTAURANTS') as DataGroupType;
    const [ isColumnVisible, setIsColumnVisible ] = useState<boolean>(false)

    // Эффект, отвечающий за запрос данных для спидометров и таблицы
    useEffect(() => {
        postMetricsSummary({
            ...currentFilters,
            ...dateFilters
        })
        postMetricsSearch({
            ...currentFilters,
            ...dateFilters,
            dataGroup: currentDataGroup,
        })
    }, [ currentFilters, dateFilters ]);

    // Эффект, отвечающий за видимость графика и запрос данных для него
    useEffect(() => {
        const dateStart = moment(dateFilters.dateStart)
        const dateEnd = moment(dateFilters.dateEnd)
        postMetricsByHour({
            ...currentFilters,
            ...dateFilters,
            searchMetric: metricsType
        })

        if (picker === 'month' && dateEnd.diff(dateStart, 'month', true) >= 1 ) {
            setIsColumnVisible(true)
            // @ts-ignore
            if ( metricsType !== 'OSAT' || metricsType !== 'RATING') {
                postMetrics({
                    ...currentFilters,
                    ...dateFilters,
                    dataGroup: 'MONTH',
                    searchMetric: metricsType
                })
            }
        } else if (picker === 'year' && dateEnd.diff(dateStart, 'year', true) >= 1 ) {
            setIsColumnVisible(true)
            // @ts-ignore
            if ( metricsType !== 'OSAT' || metricsType !== 'RATING') {
                postMetrics({
                    ...currentFilters,
                    ...dateFilters,
                    dataGroup: 'YEAR',
                    searchMetric: metricsType
                })
            }
        } else {
            setIsColumnVisible(false)
        }
    }, [ dateFilters, currentFilters, metricsType ]);

    const summaryTableData = () => {
        let newMetricsSummary = format.copy.object(happinessIndexesSummary);
        let newMetricsSearch = format.copy.object(happinessIndexesSearch) as PostMetricsSearchResponse;

        newMetricsSummary._alwaysOnTop = true;
        newMetricsSummary.name = `Все ${dropTypesFilter[ currentDataGroup ]}`;
        newMetricsSearch.content = [ newMetricsSummary, ...newMetricsSearch.content ]

        return newMetricsSearch
    }

    return (
        <div
            className={cn(className, styles.statistics_tab)}
        >
            <GuestVoteFilters dateFilters={dateFilters}/>
            <GuestVoteMetricsTypeSwitcher />
            <HappinessGauges/>
            {happinessIndexesIsLoading ? <Spinner/> : isColumnVisible && <GuestVoteColumn dateFilters={dateFilters} data={happinessIndexes} metricsType={metricsType} />}
            <GuestVoteTable
                appendHappinessIndexesSearch={appendHappinessIndexesSearch}
                currentFilters={currentFilters}
                dateFilters={dateFilters}
                dataGroup={currentDataGroup}
                postMetricsSearch={postMetricsSearch}
                data={summaryTableData()}
                columns={config.tables.guestVote.getStatisticsTable(currentDataGroup)}
            />
        </div>
    );
};

export default connect(mapStateToProps, allActions)(StatisticsTab)
