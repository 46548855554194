/* IMPORT */

import { templatesActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    elementTypes: state.templates.elementTypes,
    elementTypesLoading: state.templates.elementTypesLoading,
    elementAttributes: state.templates.elementAttributes,
    elementAttributesLoading: state.templates.elementAttributes_loading,
})

export const allActions = { ...templatesActions, }
