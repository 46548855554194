import React from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import styles from './guest-vote-column.module.scss';
import { MetricsType, PostMetricsResponse } from 'services/guestVote/type';
import { FormatedDateFilters } from 'pages/GuestVote/types';
import moment from 'moment';

type DataItem = {
    name: string;
    date: string;
    count: number;
};

type GuestVoteColumnProps = {
    data: PostMetricsResponse;
    columnWidth?: number;
    dateFilters: FormatedDateFilters;
    metricsType: MetricsType;
} & Partial<ColumnConfig>;

const formatValue = (value: number | undefined, isPercentage: boolean): string => {
    return value !== undefined ? `${value}${isPercentage ? '%' : ''}` : '';
};

const getMetricsMap = (metricsType: MetricsType, item: PostMetricsResponse[number]) => ({
    HI: {
        current: item.factHappinessIndex,
        lastYear: item.factHappinessIndexLastYear,
        label: 'HI',
        isPercentage: false,
    },
    OSAT: {
        current: item.factOsat,
        lastYear: item.factOsatLastYear,
        label: 'OSAT',
        isPercentage: true,
    },
    RATING: {
        current: item.factFeedbacksRating,
        lastYear: item.factFeedbacksRatingLastYear,
        label: 'Рейтинг',
        isPercentage: false,
    },
}[ metricsType ]);

const GuestVoteColumn = ({
    data,
    dateFilters,
    columnWidth = 38,
    metricsType,
    ...props
}: GuestVoteColumnProps) => {
    if (data.length > 12) {
        const start = moment(dateFilters.dateStart);
        data.forEach((item) => {
            item.name = start.format('MMMM YYYY');
            start.add(1, 'month');
        });
    }

    const transformedData = data.reduce<DataItem[]>((acc, item) => {
        const metric = getMetricsMap(metricsType, item);
        if (metric) {
            if (metric.lastYear) {
                acc.push({
                    name: `${metric.label} прошлый год`,
                    date: item.name,
                    count: metric.lastYear,
                });
            }
            if (metric.current) {
                acc.push({
                    name: metric.label,
                    date: item.name,
                    count: metric.current,
                });
            }
        }
        return acc;
    }, []);

    if (transformedData.length === 0) { return null; }

    return (
        <div className={styles.column}>
            <div style={{ width: `${transformedData.length * (columnWidth + 52)}px`, minWidth: 150 }}>
                <Column
                    isGroup
                    xField="date"
                    autoFit
                    data={transformedData}
                    yField="count"
                    seriesField="name"
                    color={[ '#165f99', '#9c7fc8' ]}
                    meta={{
                        count: {
                            formatter: (value: number) =>
                                formatValue(value, metricsType === 'OSAT'),
                        },
                    }}
                    label={{
                        position: 'middle',
                        style: { fill: '#ffffff' },
                        formatter: (datum) => formatValue(datum.count, metricsType === 'OSAT'),
                        layout: [
                            { type: 'interval-adjust-position' },
                            { type: 'interval-hide-overlap' },
                        ],
                    }}
                    {...props}
                />
            </div>
        </div>
    );
};

export default GuestVoteColumn;
