/* VENDOR */
import React, { Component } from 'react'
import { Link }             from 'react-router-dom'

import { Layout } from 'antd'

/* APPLICATION */

import { DashboardCard, RatingSummary, ViewTypeSwitch } from 'components'

import config from 'config'
import { allActions, mapStateToProps } from './connector'
import { connect } from 'react-redux'
import { format } from 'tools'

const { Content } = Layout

let tabCache = {}

class RatingCard extends Component {
    constructor ( props ) {
        super( props )

        this.state = { view: config.tabs.rating._default, }
    }

    componentDidMount () {
        const reqObj = {
            ...this.props.request,
            factsNumber: this.props.request.restaurantId
        }
        if (!this.props.request.currentPredef ||
        !this.props.request.dateStart ||
        !this.props.request.dateEnd) {
            return
        }
        this.props.fetchCategories( reqObj )
    }

    componentDidUpdate (prevProps, prevState) {
        if (
            format.check.request(
                this.props.request,
                prevProps.request,
                [ 'unitOfMeasure', 'restaurantId' ],
                []
            )
        ) {
            if (!this.props.request.currentPredef ||
              !this.props.request.dateStart ||
              !this.props.request.dateEnd) {
                return
            }
            tabCache = {}
            this.props.flushRating();
            this.load()
        }

        if (prevState.view !== this.state.view) {
            this.props.flushRating();
            this.load()
        }
    }

    setView = ( view ) => {
        this.setState({ view: view })
    }

    load = () => {
        if (tabCache[ this.state.view ]) {
            return;
        }

        const reqObj = {
            ...this.props.request,
            factsNumber: this.props.request.restaurantId
        }

        if (this.state.view === 'modifiers') {
            this.props.fetchModifiers( reqObj )
        } else if (this.state.view === 'categories') {
            this.props.fetchCategories( reqObj )
        } else {
            this.props.fetchDishes({ ...reqObj, all: false, hideCombo: true })
        }

    }

    data = () => {
        const { rating } = this.props,
              { view } = this.state

        if (tabCache[ view ]) { return tabCache[ view ] }

        if ( !rating[ view ]) { return null }
        if ( !rating[ view ].content ) { return null }

        const result = rating[ view ].content.filter(( i ) => i.top )

        tabCache[ view ] = result;

        return tabCache[ view ];
    }

    render () {
        const { view } = this.state

        return (
            <DashboardCard spans={[ 12, 7 ]}>
                <Layout>
                    <Content>
                        <div className="card-controls">
                            <h2>Рейтинг блюд</h2>
                            <div className="control-links">
                                <Link to="/dashboard/rating">Отчёт</Link>
                            </div>
                        </div>
                        <ViewTypeSwitch
                            update={this.setView}
                            tabs={config.tabs.rating}
                            current={view}
                        />
                        <RatingSummary data={this.data()} />
                    </Content>
                </Layout>
            </DashboardCard>
        )
    }
}

export default connect( mapStateToProps, allActions )( RatingCard )
