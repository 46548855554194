/* VENDOR */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Table, Tooltip} from 'antd'
import Icon, {InfoCircleOutlined} from '@ant-design/icons'

/* APPLICATION */
import {Icons} from 'components'
import config from 'config'

import './schedule-summary-report.scss'
import moment from 'moment'
import * as XLSX from 'xlsx'
import column from '../../../config/tables/helpers.js'
import {format} from 'tools';

class ScheduleSummaryReport extends Component {
    static propTypes = {
        data: PropTypes.object,
        updatedData: PropTypes.object,
        currentWeek: PropTypes.object,
        date: PropTypes.object,

        fetchScheduleSummary: PropTypes.func,
        fetchAllSchedules: PropTypes.func,
        deleteSchedulesEmployeesHided: PropTypes.func,
        postSchedulesEmployeesHided: PropTypes.func,
    }

    constructor (props) {
        super(props)

        this.state = {data: this.props.data || {}, }
    }

    componentDidMount () {
        this.load()
    }

    componentDidUpdate (prevProps) {
        if (this.props.updatedData && prevProps.updatedData) {
            if (!format.compare.arrays(this.props.updatedData.hidedEmployees, prevProps.updatedData.hidedEmployees)
                && this.props.updatedData.hidedEmployees?.length !== prevProps.updatedData.hidedEmployees?.length) {
                this.load()
            }
        }

        if (this.props.data !== prevProps.data) {
            this.setState({data: this.props.data})
        }
    }

    load = () => {
        const {date} = this.props

        this.props.fetchScheduleSummary(date)
    }

    setHiddedEmployees = (record) => {
        const {request, currentWeek} = this.props

        this.props.postSchedulesEmployeesHided({
            dateStart: currentWeek.dateStart,
            dateEnd: currentWeek.dateEnd,
            employeeUuid: record.employeeUuid,
            factsNumber: request.restaurantId,
            positionUuid: record.positionUuid ? record.positionUuid : null,
        }).then(() => {
            this.load();
        })
    }

    columns = () => {
        const weekDays = Array.from({length: 7}, (_, i) => moment(this.props.date).startOf('week').add(i, 'days'))

        return [
            {
                title: 'Станции',
                dataIndex: 'positionStationName',
                key: 'key',
                fixed: 'left',
                width: 110,
                render: (positionStation) => {
                    const {value, rowSpan} = positionStation
                    return {
                        children: value,
                        props: {rowSpan: rowSpan, },
                    }
                }
            },
            {
                title: 'Сотрудники',
                dataIndex: 'employeeName',
                width: 240,
                key: 'key',
                fixed: 'left',
                render: (text, record) => {
                    const transferIcon = record.transfer ? (
                        this.props.request.restaurantId == record.transfer[ 0 ].originalFactsNumber ?
                                <Icon component={Icons.Transfer.def}/> :
                                <Icon component={Icons.Temporary.def}/>
                    ) : null

                    const transferTooltipText = record.transfer ? (
                        this.props.request.restaurantId == record.transfer[ 0 ].originalFactsNumber ?
                            `Переведен в ресторан ${record.transfer[ 0 ].transferredRestName} с 
                            ${moment(record.transfer[ 0 ].dateStart).format('DD.MM.YYYY')} по 
                            ${moment(record.transfer[ 0 ].dateEnd).format('DD.MM.YYYY')}` :
                            `Переведен из ресторана ${record.transfer[ 0 ].originalRestName} с 
                            ${moment(record.transfer[ 0 ].dateStart).format('DD.MM.YYYY')} по
                             ${moment(record.transfer[ 0 ].dateEnd).format('DD.MM.YYYY')}`
                    ) : ''

                    let absenceReason
                    if (record.absence) {
                        switch (record.absence?.[ 0 ]?.reason) {
                            case 'Very very bad':
                                absenceReason = 'Не выход'
                                break
                            case 'On vacation':
                                absenceReason = 'Отпуск'
                                break
                            case 'Sick leave':
                                absenceReason = 'Больничный'
                                break
                            default:
                                absenceReason = ' '
                        }
                    }

                    const absenceText = record.absence ? `${absenceReason}. с ${moment(record.absence?.[ 0 ]?.dateStart).format('DD.MM.YYYY')} по ${moment(record.absence?.[ 0 ]?.dateEnd).format('DD.MM.YYYY')}` : ''

                    const endWorkText = record.dateEndWork ? `Уволен с ${moment(record.dateEndWork).format('DD.MM.YYYY')}` : ''

                    let tooltipText = transferTooltipText
                    if (absenceText && endWorkText) {
                        tooltipText = `${absenceText}. ${endWorkText}`
                    } else if (absenceText) {
                        tooltipText = absenceText
                    } else if (endWorkText) {
                        tooltipText = endWorkText
                    }


                    return (
                        <span className='summary-report-transfer-table-cell'>
                            <span className="transfer-icon">
                                {transferIcon ? (
                                    <Tooltip title={tooltipText}>{transferIcon}</Tooltip>
                                ) : (<Tooltip title={tooltipText}> {text} </Tooltip>)}
                                {transferIcon ? text : null}
                            </span>
                            {!Object.keys(record.days).length && (
                                <span className='cross-black-button'>
                                    <span
                                        type='link'
                                        className='remove-row-control'
                                        onClick={() => this.setHiddedEmployees(record)}
                                    >
                                        <Icon component={Icons.Remove.active}/>
                                    </span>
                                </span>
                            )}
                        </span>
                    )
                },
            },
            ...weekDays.map((day, index) => ({
                title: `${day.format('DD.MM.YYYY')} | ${day.format('dd').toUpperCase()}`,
                children: [
                    {
                        title: 'С ночи',
                        children: [
                            {
                                title: 'Начало',
                                dataIndex: `nightShiftStart_${index}`,
                                key: 'key',
                                render: (text, record) => {
                                    const shiftDateKey = day.format('YYYY-MM-DD')
                                    const shift = record.days[ shiftDateKey ]?.nightShifts[ 0 ]
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format('YYYY-MM-DD')
                                    let statusText = ''

                                    const transfer = record.transfer?.find(t =>
                                        currentDate >= moment(t.dateStart).format('YYYY-MM-DD') && currentDate <= moment(t.dateEnd).format('YYYY-MM-DD'))

                                    const absence = record.absence?.find(a =>
                                        currentDate >= moment(a.dateStart).format('YYYY-MM-DD') && currentDate <= moment(a.dateEnd).format('YYYY-MM-DD'))

                                    const isFired = record.dateEndWork && currentDate > moment(record.dateEndWork).format('YYYY-MM-DD')

                                    if (transfer) {
                                        if (!allShifts) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.originalFactsNumber) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.transferredRestFactsNumber) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if (shift) {
                                                return moment(shift.shiftStart).format('HH:mm')
                                            }
                                            return ''
                                        }
                                    } else if (isFired) {
                                        statusText = 'Уволен'
                                    } else if (absence) {
                                        switch (absence.reason) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = ''
                                        }
                                    }


                                    if (statusText) {
                                        return statusText
                                    } else {
                                        return shift ? moment(shift.shiftStart).format('HH:mm') : ''
                                    }

                                },
                                width: 110,
                            },
                            {
                                title: 'Конец',
                                dataIndex: `nightShiftEnd_${index}`,
                                key: 'key',
                                render: (text, record) => {
                                    const shiftDateKey = day.format('YYYY-MM-DD')
                                    const shift = record.days[ shiftDateKey ]?.nightShifts[ 0 ]
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format('YYYY-MM-DD')
                                    let statusText = ''

                                    const transfer = record.transfer?.find(t =>
                                        currentDate >= moment(t.dateStart).format('YYYY-MM-DD') && currentDate <= moment(t.dateEnd).format('YYYY-MM-DD'))

                                    const absence = record.absence?.find(a =>
                                        currentDate >= moment(a.dateStart).format('YYYY-MM-DD') && currentDate <= moment(a.dateEnd).format('YYYY-MM-DD'))

                                    const isFired = record.dateEndWork && currentDate > moment(record.dateEndWork).format('YYYY-MM-DD')

                                    if (transfer) {
                                        if (!allShifts) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.originalFactsNumber) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.transferredRestFactsNumber) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if (shift) {
                                                return moment(shift.shiftEnd).format('HH:mm')
                                            }
                                            return ''
                                        }
                                    } else if (isFired) {
                                        statusText = 'Уволен'
                                    } else if (absence) {
                                        switch (absence.reason) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = absence.reason
                                        }
                                    }


                                    if (statusText) {
                                        return statusText
                                    } else {
                                        return shift ? moment(shift.shiftEnd).format('HH:mm') : ''
                                    }
                                },
                                width: 110,
                            },
                        ],
                    },
                    {
                        title: 'Смена',
                        children: [
                            {
                                title: 'Начало',
                                dataIndex: `dayShiftStart_${index}`,
                                key: 'key',
                                render: (text, record) => {
                                    const shiftDateKey = day.format('YYYY-MM-DD')
                                    const shifts = record.days[ shiftDateKey ]?.dayShifts
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format('YYYY-MM-DD')
                                    let statusText = ''

                                    const transfer = record.transfer?.find(t =>
                                        currentDate >= moment(t.dateStart).format('YYYY-MM-DD') && currentDate <= moment(t.dateEnd).format('YYYY-MM-DD'))

                                    const absence = record.absence?.find(a =>
                                        currentDate >= moment(a.dateStart).format('YYYY-MM-DD') && currentDate <= moment(a.dateEnd).format('YYYY-MM-DD'))

                                    const isFired = record.dateEndWork && currentDate > moment(record.dateEndWork).format('YYYY-MM-DD')

                                    if (transfer) {
                                        if (!allShifts) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.originalFactsNumber) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.transferredRestFactsNumber) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if (shifts) {
                                                return moment(shifts[ 0 ].shiftStart).format('HH:mm')
                                            }
                                            return ''
                                        }
                                    } else if (isFired) {
                                        statusText = 'Уволен'
                                    } else if (absence) {
                                        switch (absence.reason) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = absence.reason
                                        }
                                    }


                                    if (statusText) {
                                        return statusText
                                    } else {
                                        return shifts && shifts.length > 0 ? moment(shifts[ 0 ].shiftStart).format('HH:mm') : ''
                                    }
                                },
                                width: 110,
                            },
                            {
                                title: 'Конец',
                                dataIndex: `dayShiftEnd_${index}`,
                                key: 'key',
                                render: (text, record) => {
                                    const shiftDateKey = day.format('YYYY-MM-DD')
                                    const shifts = record.days[ shiftDateKey ]?.dayShifts
                                    const allShifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                    const currentDate = day.format('YYYY-MM-DD')
                                    let statusText

                                    const transfer = record.transfer?.find(t =>
                                        currentDate >= moment(t.dateStart).format('YYYY-MM-DD') && currentDate <= moment(t.dateEnd).format('YYYY-MM-DD'))

                                    const absence = record.absence?.find(a =>
                                        currentDate >= moment(a.dateStart).format('YYYY-MM-DD') && currentDate <= moment(a.dateEnd).format('YYYY-MM-DD'))

                                    const isFired = record.dateEndWork && currentDate > moment(record.dateEndWork).format('YYYY-MM-DD')

                                    if (transfer) {
                                        if (!allShifts) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.originalFactsNumber) {
                                            statusText = transfer.status
                                        } else if (this.props.request.restaurantId == transfer.transferredRestFactsNumber) {
                                            const shifts = record.days[ shiftDateKey ]?.dayShifts || record.days[ shiftDateKey ]?.nightShifts
                                            if (shifts) {
                                                return moment(shifts[ shifts.length - 1 ].shiftEnd).format('HH:mm')
                                            }
                                            return ''
                                        } else {
                                            statusText = transfer.status
                                        }
                                    } else if (isFired) {
                                        statusText = 'Уволен'
                                    } else if (absence) {
                                        switch (absence.reason) {
                                            case 'Very very bad':
                                                statusText = 'Не выход'
                                                break
                                            case 'On vacation':
                                                statusText = 'Отпуск'
                                                break
                                            case 'Sick leave':
                                                statusText = 'Больничный'
                                                break
                                            default:
                                                statusText = absence.reason
                                        }
                                    }


                                    if (statusText) {
                                        return statusText
                                    } else {
                                        return shifts && shifts.length > 0 ? moment(shifts[ shifts.length - 1 ].shiftEnd).format('HH:mm') : ''
                                    }
                                },
                                width: 110,
                            },
                        ],
                    },
                    {
                        title: 'Позиция',
                        dataIndex: 'positionName',
                        key: 'key',
                        width: 110,
                    },
                ],
            })),
            {
                title: <div className="summary-report-tooltips">
                    <span>Часы по позициям</span>
                    <Tooltip
                        title='Запланированные в расписании часы сотрудника по позиции за неделю'>
                        <InfoCircleOutlined/>
                    </Tooltip>
                </div>,
                dataIndex: 'planSummaryPositionDuration',
                key: 'key',
                width: 120,
            },
            {
                title: <div className="summary-report-tooltips">
                    <span>Общие часы</span>
                    <Tooltip
                        title='Запланированные в расписании часы сотрудника по всем позициям за неделю'>
                        <InfoCircleOutlined/>
                    </Tooltip>
                </div>,
                dataIndex: 'planSummaryDuration',
                key: 'key',
                width: 120,
            },
            {
                title: 'Ознакомлен',
                dataIndex: 'dateEndWorks',
                key: 'key',
                width: 110
            },
        ]
    }

    transformData = (data, updatedData) => {
        const transformedData = []
        const positionRowSpanMap = {}

        updatedData?.forEach((position) => {
            positionRowSpanMap[ position.positionStationName ] =
                (positionRowSpanMap[ position.positionStationName ] || 0) + position.employees.length
        })

        updatedData?.forEach((position) => {
            const positionName = position.positionStationName
            let rowSpan = positionRowSpanMap[ positionName ]

            position?.employees?.forEach((employee) => {
                const originalEmployee = data?.find(p => p.positionStationName === positionName)?.employees?.find(e => e.employeeUuid === employee.employeeUuid && e.positionName === employee.positionName)
                let employeeData = {
                    ...employee,
                    planSummaryPositionDuration: originalEmployee?.planSummaryPositionDuration,
                    planSummaryDuration: originalEmployee?.planSummaryDuration,
                    transfer: originalEmployee?.transfer,
                    absence: originalEmployee?.absence,
                    dateEndWork: originalEmployee?.dateEndWork,
                    days: {},
                }
                const startDateIndex = moment(employee.absence?.[ 0 ]?.dateStart).day()
                const endDateIndex = moment(employee.absence?.[ 0 ]?.dateEnd).day()
                const endDateWorkIndex = employee.dateEndWork ? moment(employee.dateEndWork).day() : null

                employee.days?.forEach((day) => {
                    const dateKey = moment(day.date).format('YYYY-MM-DD')
                    const previousDateKey = moment(day.date).subtract(1, 'days').format('YYYY-MM-DD')
                    employeeData.days[ previousDateKey ] = employeeData.days[ previousDateKey ] ? employeeData.days[ previousDateKey ] : {
                        dayShifts: [],
                        nightShifts: []
                    }
                    employeeData.days[ dateKey ] = {dayShifts: [], nightShifts: []}
                    const previousDayShift = moment(employeeData?.days[ previousDateKey ]?.dayShifts?.[ employeeData?.days[ previousDateKey ]?.dayShifts?.length ? employeeData?.days[ previousDateKey ]?.dayShifts?.length - 1 : 0 ]?.shiftEnd).format('HH:mm')

                    day.shifts?.forEach((shift) => {
                        const start = moment(shift.shiftStart)

                        if (start.format('HH:mm') === '00:00' && previousDayShift === '00:00') {
                            employeeData.days[ dateKey ].nightShifts.push(shift)
                        } else {
                            employeeData.days[ dateKey ].dayShifts.push(shift)
                        }

                    })
                })

                for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
                    let dayInfo = {}
                    employee.transfer?.forEach((transfer) => {
                        const transferStartDate = moment(transfer.dateStart)
                        const transferEndDate = moment(transfer.dateEnd)
                        const currentDate = moment(this.props.date).startOf('week').add(dayIndex, 'days')

                        if (currentDate.isBetween(transferStartDate, transferEndDate, 'day', '[]')) {
                            dayInfo.status = transfer.status
                        }
                    })
                    if (employee.transfer && dayIndex >= startDateIndex && dayIndex <= endDateIndex) {
                        dayInfo = {status: employee.transfer[ 0 ].status}
                    }
                    if (employee.absence && dayIndex >= startDateIndex && dayIndex <= endDateIndex) {
                        let reason
                        switch (employee.absence[ 0 ].reason) {
                            case 'Very very bad':
                                reason = 'Не выход'
                                break
                            case 'On vacation':
                                reason = 'Отпуск'
                                break
                            case 'Sick leave':
                                reason = 'Больничный'
                                break
                            default:
                                reason = employee.absence[ 0 ].reason
                        }
                        dayInfo = {...dayInfo, reason}
                    }
                    if (endDateWorkIndex !== null && dayIndex > endDateWorkIndex) {
                        dayInfo = {...dayInfo, status: 'Уволен'}
                    }

                    if (employee.days && dayIndex < employee.days.length && employee.days[ dayIndex ] && (dayInfo.status || dayInfo.reason)) {
                        employee.days[ dayIndex ] = dayInfo
                    }
                }


                transformedData.push({
                    key: `${employee.positionName}_${employee.employeeUuid}`,
                    positionStationName: {
                        value: positionName,
                        rowSpan: rowSpan,
                    },
                    ...employeeData,
                })

                rowSpan = 0
            })
        })

        return transformedData
    }

    downloadExcel = (transformedData) => {
        const workbook = XLSX.utils.book_new()

        if (!transformedData.length) {
            return
        }

        const worksheetData = transformedData.reduce((acc, item) => {
            const baseRow = {
                'Станция': item.positionStationName.value,
                'Сотрудник': item.employeeName,
                'Позиция': item.positionName,
                'Общие часы': item.planSummaryDuration
            }

            Object.keys(item.days).forEach(date => {
                const dayShifts = item.days[ date ].dayShifts.map(shift => `${shift.shiftStart} - ${shift.shiftEnd}`).join(', ')
                const nightShifts = item.days[ date ].nightShifts.map(shift => `${shift.shiftStart} - ${shift.shiftEnd}`).join(', ')

                acc.push({
                    ...baseRow,
                    'Дата': date,
                    'Дневные Смены': dayShifts,
                    'Ночные Смены': nightShifts
                })
            })

            if (Object.keys(item.days).length === 0) {
                acc.push(baseRow)
            }

            return acc
        }, [])

        const worksheet = XLSX.utils.json_to_sheet(worksheetData, {
            header: [ 'Станция', 'Сотрудник', 'Позиция', 'Общие часы', 'Дата', 'Дневные Смены', 'Ночные Смены' ],
            skipHeader: true
        })
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Расписание')

        XLSX.writeFile(workbook, 'Расписание_сотрудников.xlsx')
    }


    spmhTcph = (data) => {
        const today = new Date(this.props.date)
        let dayOfWeek = today.getDay()
        dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek - 1

        const firstDayOfWeek = new Date(today)
        firstDayOfWeek.setDate(today.getDate() - dayOfWeek)

        let daysOfWeek = Array.from({length: 7}).map((_, index) => {
            const date = new Date(firstDayOfWeek)
            date.setDate(date.getDate() + index)
            return date
        })

        const columns = [
            {
                title: '',
                dataIndex: 'values',
                key: 'values',
            },
            ...daysOfWeek.map(day => {
                const dayKey = moment(day).format('YYYY-MM-DD')
                return {
                    title: moment(day).format('DD.MM.YYYY | dd').toUpperCase(),
                    dataIndex: moment(day).format('YYYY-MM-DD'),
                    key: moment(day).format('YYYY-MM-DD'),
                    render: (text, record) => record[ dayKey ],
                }
            }),
            {
                title: 'Итого',
                dataIndex: 'summary',
                key: 'summary',
            },
        ]

        daysOfWeek = Array.from({length: 8}).map((_, index) => {
            const date = new Date(firstDayOfWeek)
            date.setDate(date.getDate() + index)
            return date
        })

        const dataSource = [
            {
                'values': 'SPMH',
                ...daysOfWeek.reduce((acc, day) => {
                    const dayKey = day.toISOString().split('T')[ 0 ]
                    const dayData = data?.days?.find(d => moment(d.date).format('YYYY-MM-DD') === dayKey)
                    acc[ dayKey ] = dayData ? dayData.planSpmh : null
                    return acc
                }, {}),
                'summary': data ? data.planSpmh : null,
            },
            {
                'values': 'TCPH',
                ...daysOfWeek.reduce((acc, day) => {
                    const dayKey = moment(day).format('YYYY-MM-DD')
                    const dayData = data?.days?.find(d => moment(d.date).format('YYYY-MM-DD') === dayKey)
                    acc[ dayKey ] = dayData ? dayData.planTcph : null
                    return acc
                }, {}),
                'summary': data ? data.planTcph : null,
            },
        ]

        return {columns, dataSource}
    }

    turnoverAverage = () => {
        const {data} = this.props

        return [
            {
                planTurnoverRgm: data?.planTurnoverRgm ?? ' ',
                planAverageCheck: data?.planAverageCheck ?? ' ',
            },
        ]
    }

    onSelect = (record) => {
        const {deleteSchedulesEmployeesHided} = this.props

        deleteSchedulesEmployeesHided(record?.hidedEmployeeUuid).then(() => {
            this.load();
        });
    }

    hiddenEmployeesColumns = () => {
        return [
            column('Имя', 'employeeName', {
                as: 'cell',
                editable: true,
                width: 140,
            }),
            column('Позиция', 'positionName', {
                as: 'cell',
                width: 60,
            }),
            column('', '', {
                as: 'cell',
                width: 20,
                render: (record) => {
                    return (
                        <button className="check-icon" onClick={() => this.onSelect(record)}>
                            <Icon component={Icons.Undo.active}/>
                        </button>
                    )
                }
            }),
        ]
    }


    render () {
        return (
            <div className="summary-report ant-tabs-tab">
                <h2>Сводное расписание
                    <div className={'custom-tab-icon'}>
                        <Button
                            type='link'
                            className='button-download'
                            disabled={!this.props.data}
                            onClick={() => window.print()}
                        >
                            <Icon component={Icons.Print.active}/>
                        </Button>
                    </div>
                </h2>
                <div className='summary-report-reverse-table'>


                    <Table
                        columns={config.tables.schedule.summaryReport.turnoverAverage}
                        dataSource={this.turnoverAverage()}
                        pagination={false}
                        loading={!Object.keys(this.state.data).length}
                        bordered
                    />
                    <Table
                        columns={this.spmhTcph(this.state.data).columns}
                        dataSource={this.spmhTcph(this.state.data).dataSource}
                        pagination={false}
                        loading={!Object.keys(this.state.data).length}
                        bordered
                    />


                </div>

                <div className='summary-report-middle print-report-sreport'>
                    <Table
                        columns={this.columns()}
                        dataSource={this.transformData(this.props.data?.positionStations, this.props.updatedData?.positionStations)}
                        bordered
                        pagination={false}
                        loading={!Object.keys(this.state.data).length}
                        scroll={{x: 3660}}
                        rowKey='key'
                    />
                </div>
                <div className='summary-report-bottom'>
                    <h2>Скрытые сотрудники</h2>
                    <Table
                        columns={this.hiddenEmployeesColumns()}
                        dataSource={this.props.updatedData?.hidedEmployees}
                        pagination={false}
                        loading={!Object.keys(this.state.data).length}
                    />
                </div>
            </div>
        )
    }
}

export default ScheduleSummaryReport
