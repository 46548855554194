/* IMPORT */

import { templatesActions } from 'services'

/* EXPORT */

export const mapStateToProps = ( state ) => ({
    request:   state.request,
    templates: state.templates,
})

export const allActions = { ...templatesActions, }
