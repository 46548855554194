/* VENDOR */
import { update } from 'reduxigen'

/* APPLICATION */
import * as api   from './api'
import { format } from 'tools'

const asyncUpdate = format.update.asyncu,
      helper = format.helper.basic( 'templates' ),
      saveHelper = ( data, state, id ) => ({
          ...state,
          templates: {
              ...state.templates,
              list: state.templates.list.map(( i ) =>
                  i.templateId === id || i.id === id ? data : i
              ),
              current: data,
          },
      })

export const flushTemplate = update(
    'tempaltes.flushCurrent',
    ( data, state ) => ({
        ...state,
        templates: {
            ...state.templates,
            current: null,
        },
    })
)

export const fetchTemplatesGroups = asyncUpdate(
    'templates.groups',
    ( params ) => api.getGroups( params ),
    ( event, state ) => helper( 'groups', event.data, state, event.status )
)

export const fetchTemplates = asyncUpdate(
    'templates.list',
    ( params ) => api.getTemplates( params ),
    ( event, state ) => helper( 'list', event.data, state, event.status )
)

export const getTemplate = asyncUpdate(
    'templates.current',
    ( id ) => api.getTemplate( id ),
    ( event, state ) => helper( 'current', event.data, state, event.status )
)

export const postElementAttributes = asyncUpdate(
    'templates.elementAttributes',
    ( body ) => api.postElementAttributes(body),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        return {
            ...state,
            templates: {
                ...state.templates,
                elementAttributes: {
                    ...state.templates.elementAttributes,
                    ...event.data
                },
            },
        }
    }
)

export const saveTemplate = asyncUpdate(
    'templates.save',
    ( body ) =>
        body.templateId ? api.saveTemplate( body ) : api.createTemplate( body ),
    ( event, state ) => {
        const data = JSON.parse( event.config.data )

        return event.status === 200
            ? saveHelper( event.data, state, data.templateId )
            : saveHelper( data, state, data.templateId || data.id )
    }
)

export const fetchElementTypes = asyncUpdate(
    'templates.elementTypes',
    () => api.postElementTypes(),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        return {
            ...state,
            templates: {
                ...state.templates,
                elementTypes: event.data,
            },
        }
    }
)

export const removeTemplate = asyncUpdate(
    'templates.remove',
    ( id ) => api.removeTemplate( id ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return state
        }

        const id = event.config.url.split( '/' ).pop()

        return {
            ...state,
            templates: {
                ...state.templates,
                list:    state.templates.list.filter(( t ) => t.templateId !== id ),
                current: null,
            },
        }
    }
)
