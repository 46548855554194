/* VENDOR */
import moment from 'moment'

export const byMoment = ( a, b, key, order ) => {
    if ( alwaysOnTop( a )) {
        return order === 'ascend' ? -1 : 1
    }
    if ( alwaysOnTop( b )) {
        return order === 'ascend' ? 1 : -1
    }

    return moment( b[ key ]).unix() - moment( a[ key ]).unix()
}

export const byDate = ( a, b, key, order ) => {
    if ( alwaysOnTop( a )) {
        return order === 'ascend' ? -1 : 1
    }
    if ( alwaysOnTop( b )) {
        return order === 'ascend' ? 1 : -1
    }

    if ( a.startInterval && b.startInterval ) {
        return moment( a.startInterval ).unix() - moment( b.startInterval ).unix()
    }

    return parseInt( a[ key ]) - parseInt( b[ key ])
}

export const byNull = ( a, b, key, order ) => {
    if ( alwaysOnTop( a )) {
        return order === 'ascend' ? -1 : 1
    }
    if ( alwaysOnTop( b )) {
        return order === 'ascend' ? 1 : -1
    }

    if ( a[ key ] === null ) {
        return -1
    }
    if ( b[ key ] === null ) {
        return 1
    }

    return parseFloat( a[ key ]) - parseFloat( b[ key ])
}

export const stringArray = ( a, b, key ) => {
    if ( alwaysOnTop( a )) {
        return -1
    }
    if ( alwaysOnTop( b )) {
        return 1
    }

    const sa = a[ key ].sort(( aa, ab ) => aa.localeCompare( ab )),
          sb = b[ key ].sort(( ba, bb ) => ba.localeCompare( bb ))
    return sa.join( '' ).localeCompare( sb.join( '' ))
}

export const objectArray = ( a, b, key, sub ) => {
    if ( alwaysOnTop( a )) {
        return -1
    }
    if ( alwaysOnTop( b )) {
        return 1
    }

    const sa = a[ key ].sort(( aa, ab ) => aa[ sub ].localeCompare( ab[ sub ])),
          sb = b[ key ].sort(( ba, bb ) => ba[ sub ].localeCompare( bb[ sub ]))
    return sa.join( '' ).localeCompare( sb.join( '' ))
}

export const locale = ( a, b, key, order ) => {
    if ( alwaysOnTop( a )) {
        return order === 'ascend' ? -1 : 1
    }
    if ( alwaysOnTop( b )) {
        return order === 'ascend' ? 1 : -1
    }

    return a[ key ].localeCompare( b[ key ])
}

export const def = ( a, b, order ) => {
    if ( alwaysOnTop( a )) {
        return order === 'ascend' ? -1 : 1
    }
    if ( alwaysOnTop( b )) {
        return order === 'ascend' ? 1 : -1
    }

    return b - a
}

export const alwaysOnTop = ( obj ) => obj._alwaysOnTop
