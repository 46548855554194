import column from './helpers';
import {
    dropTypesColumns
} from '../../components/guestVote/GuestVoteTable/const';
import {Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import React from 'react';
import {DataGroupType} from 'services/guestVote/type';

const getStatisticsTable = (currentDataGroup: DataGroupType) => {
    const columns = [
        currentDataGroup === 'RESTAURANTS' ? column('Код', 'id', {
            sorter: 'bynull',
            width: 93,
        }) : null,
        column(`${dropTypesColumns[currentDataGroup]}`, 'name', {
            width: 300,
            sorter: 'locale'
        }),
        column('HI', 'factHappinessIndex', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true,
            width: 60,
        }),
        column(<div className="delta-turnover">
            <span>HI <br/> прошлый год</span>
        </div>, 'factHappinessIndexLastYear', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column(<div className="delta-turnover">
            <span>Разница HI</span>
            <Tooltip title='Разница между «HI» и «HI прошлый год»'>
                <InfoCircleOutlined onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}/>
            </Tooltip>
        </div>, 'deltaFactHappinessIndexLastYear', {
            as: 'deltaCurrency',
            sorter: 'bynull',
            numeric: true
        }),
        column('OSAT', 'factOsat', {
            as: 'deltaPercentage',
            sorter: 'bynull',
            numeric: true
        }),
        column(<div className="delta-turnover">
            <span>OSAT <br/> прошлый год</span>
        </div>, 'factOsatLastYear', {
            as: 'deltaPercentage',
            sorter: 'bynull',
            numeric: true
        }),
        column(<div className="delta-turnover">
            <span>Разница OSAT</span>
        </div>, 'deltaFactOsatLastYear', {
            as: 'deltaPercentageColor',
            sorter: 'bynull',
            numeric: true
        }),
        column('Рейтинг', 'factFeedbacksRating', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column(<div className="delta-turnover">
            <span>Рейтинг <br/> прошлый год</span>
        </div>, 'factFeedbacksRatingLastYear', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column('Обращения', 'factFeedbacks', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column(<div className="delta-turnover">
            <span>Транзакции</span>
            <Tooltip
                title='Важно! Транзакции за текущий и предыдущий дни могут быть загружены не полностью'>
                <InfoCircleOutlined onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}/>
            </Tooltip>
        </div>, 'factTransactions', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
        column(<div className="delta-turnover">
            <span>Обращения на<br/> 10К транзакций</span>
        </div>, 'factFeedbacksPer10KTransactions', {
            as: 'currency',
            sorter: 'bynull',
            numeric: true
        }),
    ];


    return columns.filter(col => col !== null);
}

export default {guestVote: {getStatisticsTable}}
