import React, { useEffect, useState } from 'react';
import Report from '../../layout/Report/Report';
import ViewTypeDrop from '../../layout/ViewTypeDrop/ViewTypeDrop';
import { GuestVoteSearchFilterType } from '../GuestVoteFilters/type';
import { dropTypesMap } from './const';
import { TableProps } from 'antd';

import './guest-vote-table.scss';
import {LoadMoreButton} from '../../index';
import {GuestVoteFiltersStoreProps, mapStateToProps} from './connector';
import {connect} from 'react-redux';
export const STORAGE_KEY = 'guestVoteTableGroup';

type GuestVoteTableProps = {
    data: any,
    postMetricsSearch: any,
    appendHappinessIndexesSearch: any,
    currentFilters: any,
    dateFilters: any,
    dataGroup: any,
} & TableProps<any>;

export const GuestVoteTable = ({ data, columns, ...props }: GuestVoteTableProps & GuestVoteFiltersStoreProps) => {
    const [ dropState, setDropState ] = useState<string>(dropTypesMap.RESTAURANTS);
    const [ currentPage, setCurrentPage ] = useState(1);
    const {
        currentFilters,
        dateFilters,
        postMetricsSearch,
        appendHappinessIndexesSearch,
        happinessIndexesSearchIsLoading,
        happinessIndexesSummaryIsLoading,
        happinessIndexesSearchAppendIsLoading
    } = props;

    const handleSetDropState = (state: GuestVoteSearchFilterType) => {
        sessionStorage.setItem(STORAGE_KEY, state);
        setDropState(state);
        setCurrentPage(1)

        postMetricsSearch({
            dataGroup: state,
            ...currentFilters,
            ...dateFilters,
        })
    }

    useEffect(() => {
        const currentDropState = sessionStorage.getItem(STORAGE_KEY)

        if (currentDropState) {
            setDropState(currentDropState);
        } else {
            sessionStorage.setItem(STORAGE_KEY, 'RESTAURANTS');
        }
    })

    const isTableLoading = Boolean(happinessIndexesSummaryIsLoading) || Boolean(happinessIndexesSearchIsLoading)
    // @ts-ignore
    return (
        <div className='GuestVoteTable'>
            <div className='dropdown_wrapper'>
                <ViewTypeDrop
                    current={dropState}
                    placeholder={dropState}
                    update={handleSetDropState}
                    items={dropTypesMap}
                />
                <span className='support_text'>Выберите, в каком разрезе отобразить данные в таблице</span>
            </div>
            <Report
                unit="none"
                scroll={{y: 500}}
                // @ts-ignore
                rowKey="name"
                loading={isTableLoading}
                data={data ?? {}}
                // @ts-ignore
                columns={columns}
                {...props}
            />
            {
                (currentPage !== data.totalPages) && data.totalPages !== 0 && !isTableLoading && (
                    <LoadMoreButton
                        loading={happinessIndexesSearchAppendIsLoading}
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                            appendHappinessIndexesSearch({
                                body: {
                                    dataGroup: dropState,
                                    ...currentFilters,
                                    ...dateFilters,
                                },
                                params: {page: currentPage, }
                            });
                        }} />
                )
            }
        </div>
    );
};

export default connect(mapStateToProps)(GuestVoteTable)
