import { IState } from 'store'

export const mapStateToProps = (state: IState) => ({
    happinessIndexesSearch: state.guestVote.happinessIndexesSearch,
    happinessIndexesSearchIsLoading: state.guestVote.happinessIndexesSearch_loading,
    happinessIndexesSummary: state.guestVote.happinessIndexesSummary,
    happinessIndexesSummaryIsLoading: state.guestVote.happinessIndexesSummary_loading,
})


export type GustVoteTabsStoreProps = ReturnType<typeof mapStateToProps>

