import * as api from './api'
import {IState, storeAsyncUpdate, storeUpdate} from 'store';
import {FiltersConfigGroup, FiltersKeys} from 'components/guestVote/GuestVoteFilters/type';
import {
    MetricsType,
    PostFeedbackFiltersSearchPayload,
    PostFeedbackFiltersSearchResponse,
    PostMetricsPayload,
    PostMetricsResponse,
    PostMetricsSearchBody, PostMetricsSearchResponse,
    PostMetricsSummaryPayload,
    PostMetricsSummaryResponse
} from './type';

type FiltersPayload = {
    value: string | number;
    key: FiltersKeys;
    group: FiltersConfigGroup;
}

export const setCurrentFilters = storeUpdate<FiltersPayload>(
    'guestVote.set.currentFilters',
    ({key, group, value}, state) => {
        if (group) {
            // @ts-ignore
            const oldValue = (state.guestVote.currentFilters[ group ]?.[ key ] || []) as (string[] | number[])
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    currentFilters: {
                        ...state.guestVote.currentFilters,
                        [ group ]: {
                            ...state.guestVote.currentFilters[ group ],
                            [ key ]: [ ...oldValue, value ]
                        }
                    }
                }
            }
        }
        // @ts-ignore
        const oldValue = (state.guestVote.currentFilters[ key ] || []) as (string[] | number[])
        return {
            ...state,
            guestVote: {
                ...state.guestVote,
                currentFilters: {
                    ...state.guestVote.currentFilters,
                    [ key ]: [ ...oldValue, value ]
                }
            }
        }
    }
)

export const deselectCurrentFilters = storeUpdate<FiltersPayload>(
    'guestVote.deselect.currentFilters',
    ({key, group, value}, state) => {
        if (group) {
            // @ts-ignore
            const oldValue = (state.guestVote.currentFilters[ group ]?.[ key ] || []) as (string[] | number[])
            const newValue = oldValue.filter((item) => item !== value)
            if (newValue.length === 0) {
                return clearFilter(group, key, state)
            }
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    currentFilters: {
                        ...state.guestVote.currentFilters,
                        [ group ]: {
                            ...state.guestVote.currentFilters[ group ],
                            [ key ]: newValue
                        }
                    }
                }
            }
        }
        // @ts-ignore
        const oldValue = (state.guestVote.currentFilters[ key ] || []) as (string[] | number[])
        const newValue = oldValue.filter((item) => item !== value)
        if (newValue.length === 0) {
            return clearFilter(group, key, state)
        }

        return {
            ...state,
            guestVote: {
                ...state.guestVote,
                currentFilters: {
                    ...state.guestVote.currentFilters,
                    [ key ]: newValue
                }
            }
        }
    }
)

export const clearCurrentFilters = storeUpdate<Omit<FiltersPayload, 'value'>>(
    'guestVote.clear.currentFilters',
    ({key, group}, state) => {
        return clearFilter(group, key, state)
    }
)

export const flushCurrentFilters = storeUpdate(
    'guestVote.flush.currentFilters',
    (_, state) => ({
        ...state,
        guestVote: {
            ...state.guestVote,
            currentFilters: {}
        }
    })
)

export const flushFilterOptions = storeUpdate(
    'guestVote.flush.filterOptions',
    (_, state) => ({
        ...state,
        guestVote: {
            ...state.guestVote,
            categoriesFiltersOptions: {
                searchFilterOptions: [],
                searchFilter: ''
            }
        }
    })
)

export const setLastSelectedFilter = storeUpdate<PostFeedbackFiltersSearchResponse| undefined>(
    'guest.set.lastSelectedFilter',
    (lastFilter, state) => ({
        ...state,
        guestVote: {
            ...state.guestVote,
            lastSelectedFilter: lastFilter
        }
    })
)

export const setMetricsType = storeUpdate<MetricsType>(
    'guestVote.set.metricsType',
    (metricsType, state) => ({
        ...state,
        guestVote: {
            ...state.guestVote,
            metricsType
        }
    })

)


//TODO: Типизировать функции в tools/format/helper.js и использовать их для внесения состояния в store
/*
    * @description Функция для получения значений в фильтры по категориям
 */
export const postFeedbackFiltersSearch = storeAsyncUpdate<PostFeedbackFiltersSearchPayload, PostFeedbackFiltersSearchResponse>(
    'guestVote.categoriesFiltersOptions',
    (body) => api.postFeedbackFiltersSearch(body),
    (event, state) => {
        if (event.status === 200) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    categoriesFiltersOptions: event.data
                }
            }
        }
        return state
    }
)

/*
    @description Функция, вызываемая при инициализация таба "Статистика", используется для заполнения спидометров с временным промежутком
 */
export const postMetricsByHour = storeAsyncUpdate<Omit<PostMetricsPayload, 'dataGroup'>, PostMetricsResponse>(
    'guestVote.happinessIndexesByHour',
    (body) => api.postMetricsByHour({...body, dataGroup: 'HOUR'}),
    (event, state) => {
        if (event.status === 200) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    happinessIndexesByHour: event.data
                }
            }
        }
        return state
    }
)

/*
    @description Функция используется для получения данных для графика в виде гисторграммы на табе "Статистика"
 */
export const postMetrics = storeAsyncUpdate<Omit<PostMetricsPayload, 'dataGroup'> & {
    dataGroup: 'MONTH' | 'YEAR'
}, PostMetricsResponse>(
    'guestVote.happinessIndexes',
    (body) => api.postMetrics(body),
    (event, state) => {
        if (event.status === 200) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    happinessIndexes: event.data
                }
            }
        }

        return state
    }
)

/*
    @description Функция, вызывается при инициализации таба "Статистика". Данные используются для первой строки таблицы и общего спидометра

 */
export const postMetricsSummary = storeAsyncUpdate<PostMetricsSummaryPayload, PostMetricsSummaryResponse>(
    'guestVote.happinessIndexesSummary',
    (body) => api.postMetricsSummary(body),
    (event, state) => {
        if (event.status === 200) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    happinessIndexesSummary: event.data
                }
            }
        }

        return state
    }
)

/*
    @description Функция, вызывается при инициализации таба "Статистика". Данные используются для заполнения таблицы
 */
export const postMetricsSearch = storeAsyncUpdate<PostMetricsSearchBody, PostMetricsSearchResponse>(
    'guestVote.happinessIndexesSearch',
    (body) => api.postMetricsSearch({body}),
    (event, state) => {
        if (event.status === 200) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    happinessIndexesSearch: event.data
                }
            }
        }
        return state
    }
)

/*
    @description Функция, вызывается при пагинации в таблице на табе "Статистика". Данные используются для добавления строк в таблицу
 */
export const appendHappinessIndexesSearch = storeAsyncUpdate<{
    body: PostMetricsSearchBody,
    params: { page: number }
}, PostMetricsSearchResponse>(
    'guestVote.happinessIndexesSearchAppend',
    (payload) => api.postMetricsSearch(payload),
    (event, state) => {
        if (event.status === 200) {
            return {
                ...state,
                guestVote: {
                    ...state.guestVote,
                    happinessIndexesSearch: {
                        ...state.guestVote.happinessIndexesSearch,
                        content: [
                            ...state.guestVote.happinessIndexesSearch.content,
                            ...event.data.content
                        ],
                    }
                }
            }
        }
        return state
    }
)


function clearFilter (group: FiltersConfigGroup, key: FiltersKeys, state: IState) {
    if (group) {
        // @ts-ignore
        const {[ key ]: _, ...rest} = state.guestVote.currentFilters[ group ]
        return {
            ...state,
            guestVote: {
                ...state.guestVote,
                currentFilters: {
                    ...state.guestVote.currentFilters,
                    [ group ]: rest
                }
            }
        }
    }
    // @ts-ignore
    const {[ key ]: _, ...rest} = state.guestVote.currentFilters
    return {
        ...state,
        guestVote: {
            ...state.guestVote,
            currentFilters: rest
        }
    }
}
