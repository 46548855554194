/* VENDOR */
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { connect }          from 'react-redux'
import moment               from 'moment'

import { LineMarkSeries, XAxis, GradientDefs } from 'react-vis'

import { Scrollbars } from 'react-custom-scrollbars'

/* APPLICATION */
import ChartCross   from './ChartCross'
import ChartHint    from './ChartHint'
import ChartPlot    from './ChartPlot'
import ChartSpin    from './ChartSpin'
import overlaps     from './overlaps'
import * as helpers from './chartHelpers'

import { format } from 'tools'
import config     from 'config'

import './card-chart.scss'

class CardChart extends Component {
    static propTypes = {
        plan:   PropTypes.array,
        fact:   PropTypes.array,
        offset: PropTypes.string,

        height: PropTypes.number,
        unit:   PropTypes.string,

        compare: PropTypes.func,
    }

    constructor ( props ) {
        super( props )
        this.uniq = format.generate.uniq()
        this.state = {
            hint:    null,
            animate: true,
        }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps ( nextProps ) {
        const { plan, fact } = this.props

        if ( plan !== nextProps.plan || fact !== nextProps.fact ) {
            this.setState({ animate: false })
        }
    }

    componentDidUpdate ( prevProps ) {
        if ( prevProps.fact !== this.props.fact ) {
            this.setState({ animate: true })
            this.waitScroll()
        }
    }

    hideHint = () =>
        this.setState({
            hint:    null,
            animate: true,
        })

    showHintFact = ( value ) => {
        const nval = format.copy.object( value )

        if ( !nval.y ) {
            nval.y = null
        }

        nval.type = 'fact'

        this.setState({ hint: nval, animate: false })
    }

    showHintPlan = ( value ) => {
        const nval = format.copy.object( value )

        if ( !nval.y ) {
            nval.y = null
        }

        nval.type = 'plan'

        this.setState({ hint: nval, animate: false })
    }

    scrollToCurrent = () =>
        this.scrolls.scrollLeft(
            ( this.props.fact.length - 1 ) * config.ui.riskSize -
        this.scrolls.container.offsetWidth / 2
        )

    waitScroll = () => {
        if ( helpers.hideCross( this.props.request )) { return }
        this.scrolls ? this.scrollToCurrent() : setTimeout( this.waitScroll, 100 )
    }

    height = () =>
        this.props.height ? this.props.height : config.ui.chartHeight

    styles = {
        axis: {
            line: {
                stroke:      '#EBEBEB',
                strokeWidth: '1px',
            },
            text: {
                stroke:   'none',
                fill:     '#7C7C7C',
                fontSize: '10px',
            },
        },

        scrolls: () => ({
            width:  '100%',
            //Chart padding offset 30 for scrollbars
            height: this.height() + 30,
        }),
    }

    config = {
        axis: () => ({
            style:      this.styles.axis,
            tickValues: this.props.plan.map(( item ) => item.time ),
            tickFormat: ( v, index ) => {
                if ( this.props.request.dateGroup === 'day' ) {
                    //Странный фикс, там с time что-то не так
                    const date = moment.unix( this.props.plan[ index ].time )

                    //;( this.findSame() ) && ( date.subtract( 1, 'days' ) )

                    return (
                        <text
                            textAnchor="middle"
                            dy="0.72em"
                            transform="translate(0, 4)"
                            className="rv-xy-plot__axis__tick__text"
                            style={{
                                stroke:   'none',
                                fill:     'rgb(124, 124, 124)',
                                fontSize: '10px',
                            }}
                        >
                            <tspan x="0" dy="1.4em">
                                {date.format( config.format.onlyDayViewShort )}
                            </tspan>
                            <tspan x="0" dy="1.4em">
                                {date.format( 'ddd' ).toUpperCase()}
                            </tspan>
                        </text>
                    )
                }
                return this.props.plan[ index ].label
            },
            tickSize: 4,
        }),

        scrolls: () => ({
            ...config.ui.scrolls,
            style: this.styles.scrolls(),
            ref:   ( node ) => ( this.scrolls = node ),
        }),

        plan: () => ({
            size:             3.5,
            color:            '#B8B8B8',
            curve:            'curveMonotoneX',
            data:             this.props.plan,
            //getNull: item => item.planValue !== null || item.time === this.props.plan[0].time,
            onValueMouseOver: this.showHintPlan,
            onValueMouseOut:  this.hideHint,
        }),

        fact: () => ({
            size:             3.5,
            colorType:        'literal',
            curve:            'curveMonotoneX',
            lineStyle:        { stroke: 'url(#' + this.uniq + '-gradient)' },
            data:             this.props.fact,
            //getNull: item => item.factValue !== null || item.time === this.props.fact[0].time,
            onValueMouseOver: this.showHintFact,
            onValueMouseOut:  this.hideHint,
        }),

        plot: () => ({
            fact:    this.props.fact,
            plan:    this.props.plan,
            single:  this.props.unit === '%',
            animate: this.state.animate,
            height:  this.height(),
        }),

        gradient: ( props ) => {
            const res = {
                id: this.uniq + '-gradient',
                x1: '0',
                x2: '100%',
                y1: '0',
                y2: '0',
            }

            this.isFlat( props ) && ( res.gradientUnits = 'userSpaceOnUse' )
            return res
        },
    }

    isFlat = ( props ) =>
        props && props.fact
            ? props.fact.every(
                ( p ) =>
                    format.check.realNull( p.y ) ===
            format.check.realNull( props.fact[ 0 ].y )
            )
            : false

    findSame = () => {
        const { fact, plan } = this.props,
              start = fact.length - 1

        return plan.find(( i ) => fact[ start ] && i.time === fact[ start ].time )
    }

    tickStyles = () => {
    /*.ant-table-body[style^="overflow-x"] {
                margin-bottom: -${format.check.scrollbarWidth() - 1}px;
            }*/

        const { fact, plan } = this.props

        if ( !fact || !plan ) { return '' }
        if ( fact.length === plan.length ) { return '' }

        const start = fact.length - 1,
              max = plan.length + 1,
              last = fact[ start ] && this.findSame() ? start + 1 : start

        let res = ''

        for ( let i = start + 1; i < max; i++ ) {
            const selector =
        '#' + this.uniq + ' .rv-xy-plot__axis__tick:nth-child(' + i + ')'

            res += selector + ' line {'
            res += 'stroke: #EBEBEB !important;'
            res += '}'

            res += selector + ' text {'
            res += 'fill: #B8B8B8 !important;'
            res += '}'
        }

        res +=
      '#' +
      this.uniq +
      ' .rv-xy-plot__axis__tick:nth-child(' +
      last +
      ') line {'
        res += 'stroke: #7C7C7C !important;'
        res += '}'

        res +=
      '#' +
      this.uniq +
      ' .rv-xy-plot__axis__tick:nth-child(' +
      last +
      ') text {'
        res += 'fill: #151515 !important;'
        res += '}'

        return res
    }

    roffset = () => -new Date().getTimezoneOffset() / 60

    offset = () => format.strings.delta( this.roffset())

    timeInRest = ( request, restaurants ) => {
        const rest = ( restaurants && restaurants.list && request )
            ? restaurants.list.find(( r ) => r.factsNumber === parseInt( request.restaurantId ))
            : null


      const offset = this.props.restaurants.current.timeZoneName ?? this.offset()

      const hour = this.props.offset ? this.props.offset * 60 : parseInt(offset.replace( 'GMT+', '' )) * 60 * 60
      const time = hour ? Math.floor( moment.tz().valueOf() / 1000 ) + hour : 0
      return time || 0
    }

    hideCross = () => {
        const { request } = this.props

        const timeZone = this.props.restaurants.current.timeZoneName
        const startWithOffset = timeZone ? moment(request.dateStart).startOf('day') : moment(request.dateStart);
        const endWithOffset = timeZone ? moment(request.dateEnd).endOf(('day')) : moment(request.dateEnd);
        const dateWithOffset = timeZone ? moment().utcOffset(timeZone) : moment();

        return startWithOffset.isAfter(dateWithOffset) || endWithOffset.isBefore(dateWithOffset)
    }


    render () {
        const { props, state, config } = this

        if ( !props.plan || !props.fact ) {
            return <ChartSpin height={this.height()} />
        }

        return (
            <div
                id={this.uniq}
                className={'card-chart scroll-container' + overlaps( this.props.plan )}
                onDoubleClick={this.waitScroll}
            >
                <style dangerouslySetInnerHTML={{ __html: this.tickStyles() }} />
                <Scrollbars {...config.scrolls()}>
                    <ChartPlot {...config.plot()}>
                        <GradientDefs>
                            <linearGradient {...config.gradient( props )}>
                                {helpers.stops( props.fact, props.compare )}
                            </linearGradient>
                        </GradientDefs>

                        <LineMarkSeries {...config.plan()} />
                        <LineMarkSeries {...config.fact()} />

                        <XAxis {...config.axis()} />

                        <ChartCross
                            hide={this.hideCross()}
                            time={this.timeInRest( props.request, props.restaurants )}
                            value={props.fact[ props.fact.length - 1 ]}
                        />

                        <ChartHint
                            hint={state.hint}
                            unit={props.unit}
                            compare={props.compare}
                        />
                    </ChartPlot>
                </Scrollbars>
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({ request: state.request, restaurants: state.restaurants })

export default connect( mapStateToProps )( CardChart )
